/* global ve */
import moment from 'moment'
import {
  getVeEnvLoc,
  addToLog,
  doLog,
  hasProp,
  ensureLongISO,
  longISOToShortISO,
  shortISOToLongISO,
  shortISOUtcToLocalByTzOffset,
  toLowerCase,
  toFirstUpperCase,
  getUniqueArray,
  scrollToCenter,
  getUserProfile,
  isValidUuidv4,
  roundTo,
  getIndicesOf,
  longISOAddSeconds
} from './veb.js'

// const veb = require('./../js/veb.js')

export default {
  computed: {
    isFormValid () {
      const isFormValid = !this.areParamValuesValid.find(
        (page) => page.indexOf(false) !== -1
      )
      return isFormValid
    },
    areRequiredParamsSetInAllPages () {
      const areRequiredParamsSetInAllPages = !this.contractDef.uiConfig[
        this.configType
      ]?.find((page, pageIndex) => !this.areRequiredParamsSet(pageIndex))
      return areRequiredParamsSetInAllPages
    }
  },
  methods: {
    roundTo (value, decimalPlaces) {
      return roundTo(value, decimalPlaces)
    },
    selectAllListRows () {
      if (!this[this.tableRef + 'AllRowsSelected']) this[this.tableRef + 'AllRowsSelected'] = true
      this[this.tableRef + 'AllRowsUnSelected'] = false
      const idsInPage = this[this.tableRef + 'ItemsDisplayed'].map((item) => item[this.itemId])
      const setSelectedIds = new Set(this[this.tableRef + 'SelectedIdsPreRefresh'].concat(idsInPage))
      this[this.tableRef + 'SelectedIdsPreRefresh'] = Array.from(setSelectedIds)
      this.$refs[this.tableRef].selectAllRows()
    },
    clearAllListRows () {
      if (this.view.includes('defaultOffers')) {
        this.selectedContract = ''
        this.showAssignContractForm = false
      }
      if (this[this.tableRef + 'AllRowsSelected']) this[this.tableRef + 'AllRowsSelected'] = false
      this[this.tableRef + 'AllRowsUnSelected'] = true
      const idsInPage = this[this.tableRef + 'ItemsDisplayed'].map((item) => item[this.itemId])
      const filteredSelectedIds = this[this.tableRef + 'SelectedIdsPreRefresh'].filter((id) => !idsInPage.includes(id))
      this[this.tableRef + 'SelectedIdsPreRefresh'] = filteredSelectedIds
      this.$refs[this.tableRef].clearSelected()
    },
    toggleSelectAllListRows () {
      addToLog('toggleSelectAllRows:tableName: ' + this.tableRef, this.debug)
      if (this[this.tableRef + 'Selected'].length === this[this.tableRef + 'ItemsDisplayed'].length) {
        this.clearAllListRows()
      } else {
        this.selectAllListRows()
      }
    },
    initListRowsSelected () {
      const currentSelected = []
      for (const item of this[this.tableRef + 'ItemsDisplayed']) {
        const isPreviousSelected = this[this.tableRef + 'SelectedIdsPreRefresh'].includes(item[this.itemId])
        if (isPreviousSelected) {
          this.selectListRow(item)
          // currentSelected.push(this.getDisplayedRowId(item))
          currentSelected.push(item)
        }
      }
      const id = setTimeout(() => {
        clearTimeout(id)
        this.onListRowSelected(currentSelected)
      }, 0)
    },
    getDisplayedRowId (item) {
      const thisId = item[this.itemId]
      let displayedRowId = null
      let i = 0
      for (const itemDisplayed of this[this.tableRef + 'ItemsDisplayed']) {
        if (thisId === itemDisplayed[this.itemId]) displayedRowId = i
        i++
      }
      return displayedRowId
    },
    selectListRow (item) {
      const fn = 'selectListRow'
      doLog(fn, { tableRef: this.tableRef, item }, this.debug)
      const clickedId = item[this.itemId]
      const rowId = this.getDisplayedRowId(item)
      this.$refs[this.tableRef].selectRow(rowId)
      const index = this[this.tableRef + 'SelectedIdsPreRefresh'].indexOf(clickedId)
      if (index === -1) this[this.tableRef + 'SelectedIdsPreRefresh'].push(clickedId)
    },
    unSelectListRow (item) {
      const fn = 'unSelectListRow'
      doLog(fn, { tableRef: this.tableRef, item }, this.debug)
      const clickedId = item[this.itemId]
      const rowId = this.getDisplayedRowId(item)
      this.$refs[this.tableRef].unselectRow(rowId)
      const index = this[this.tableRef + 'SelectedIdsPreRefresh'].indexOf(clickedId)
      if (index > -1) this[this.tableRef + 'SelectedIdsPreRefresh'].splice(index, 1)
    },
    onListRowSelected (items) {
      this.selected = items
      this[this.tableRef + 'Selected'] = items
      if (
        items?.length &&
        items.length === this[this.tableRef + 'ItemsDisplayed'].length
      ) {
        this[this.tableRef + 'AllRowsSelected'] = true
        this[this.tableRef + 'AllRowsUnSelected'] = false
      } else {
        this[this.tableRef + 'AllRowsSelected'] = false
        this[this.tableRef + 'AllRowsUnSelected'] = true
      }
    },
    getNewPagination (offset) {
      this.pagination.currentPage = 1
      const pagination = { currentPage: 1, perPage: offset }
      return pagination
    },
    copyToClipboard (value) {
      const fn = 'copyToClipboard'
      doLog(fn, { value }, true) // remove after testing
      if (typeof value === 'object') value = JSON.stringify(value)
      if (value === 'null') {
        navigator.clipboard.writeText('')
      } else {
        this.displayToast('info', 'Copy to Clipboard', value.length > 50 ? `${value.slice(0, 47)}...` : value, false, 1000)
        navigator.clipboard.writeText(value)
      }
    },
    getCurrentContext () {
      const fn = 'getCurrentContext'
      const veEnvLoc = getVeEnvLoc()
      const context = window.localStorage.getItem(`${veEnvLoc}/context/${this.docClass}`)
      doLog(fn, { veEnvLoc, context }, this.debug)
      let owner = null
      if (context) {
        owner = context.split('/')[0]
      } else if (window.sessionStorage.getItem('owner')) {
        owner = window.sessionStorage.getItem('owner')
      }
      if (context && typeof context === 'string') this.currentOwner = owner
      doLog(fn, { owner, currentOwner: this.currentOwner, context }, this.debug)
      return context
    },
    getCurrentOwner () {
      const fn = 'getCurrentOwner'
      const veEnvLoc = getVeEnvLoc()
      let owner = null
      if (this.docClass) {
        const context = window.localStorage.getItem(`${veEnvLoc}/context/${this.docClass}`)
        doLog(fn, { docClass: this.docClass, context }, this.debug)
        if (context) owner = context.split('/')[0]
      } else if (window.sessionStorage.getItem('owner')) {
        owner = window.sessionStorage.getItem('owner')
      } else {
        owner = this.getCurrentOwnerFromUserProfile()
      }
      doLog(fn, { owner }, this.debug)
      return owner
    },
    getCurrentOwnerFromUserProfile () {
      const fn = 'getCurrentOwnerFromUserProfile'
      const userProfile = getUserProfile(this.debug)
      const owner = userProfile.securityContext?.split('/')[0]
      doLog(fn, { owner, userProfile }, this.debug)
      return owner
    },
    // getRolesContextSecurityContextsFromAccessProfile () {
    //   const fn = 'getRolesContextSecurityContextsFromAccessProfile'
    //   const accessProfile = getAccessProfile(this.debug)
    //   const rolesContextSecurityContexts = accessProfile.rolesContextSecurityContexts
    //   doLog(fn, { rolesContextSecurityContexts }, this.debug)
    //   return rolesContextSecurityContexts
    // },
    // filterOnRolesContextSecurityContexts () {
    //   const fn = 'filterOnRolesContextSecurityContexts'
    //   const allAccessScs = this.getRolesContextSecurityContextsFromAccessProfile()
    //   const accessScs = allAccessScs.filter(sc => sc.includes(`/${this.docClass}`)) // ['VE/CONTRACTS', 'Voltello/CONTRACTS/NTPWC',... ]
    //   doLog(fn, { accessScs }, this.debug)
    //   const mandatoryAndCustomSecurityContexts = []
    //   for (const sc of this.mandatoryAndCustomSecurityContexts) {
    //     for (const accessSc of accessScs) {
    //       if (sc.startsWith(accessSc)) mandatoryAndCustomSecurityContexts.push(sc)
    //     }
    //   }
    //   doLog(fn, { mandatoryAndCustomSecurityContexts, accessScs }, this.debug)
    //   this.mandatoryAndCustomSecurityContexts = mandatoryAndCustomSecurityContexts
    // },
    setCurrentOwner (owner) {
      const fn = 'setCurrentOwner'
      if (typeof owner === 'string') {
        doLog(fn, { owner }, this.debug)
        window.sessionStorage.setItem('owner', owner)
        this.currentOwner = owner
      }
    },
    extractSecurityContext (context, index) {
      if (context) {
        if (context.startsWith('/')) context = context.slice(1)
        if (context.endsWith('/')) context = context.slice(0, -1)
        const splitContext = context.split('/')
        if (!index) {
          const uuidIndex = splitContext.findIndex((p) => isValidUuidv4(p))
          const spliceIndex = uuidIndex > -1 ? uuidIndex : splitContext.length - 1
          splitContext.splice(spliceIndex)
        } else {
          splitContext.splice(splitContext.length - index, index)
        }
        return splitContext.join('/')
      }
      return context
    },
    convertDateFormat (value) {
      if (value && value !== 'n/a') return moment.utc(value).format('llll')
      else return 'n/a'
    },
    formatDateWithTzOffset (value, tzOffset, format = 'llll') { // '2023-03-09T07:00:31.944235+00:00', '+08:00'
      // const fn = 'formatDateWithTzOffset'
      if (value && value !== 'n/a') {
        value = ensureLongISO(value)
        if (!(value.endsWith('Z') || value.endsWith('+00:00'))) value = `${value.split('.')[0]}Z`
        const shortIsoUtc = longISOToShortISO(value)
        const shortIsoLocal = shortISOUtcToLocalByTzOffset(shortIsoUtc, tzOffset)
        const longIsoLocal = shortISOToLongISO(shortIsoLocal)
        const formattedDate = moment(longIsoLocal).format(format)
        // doLog(fn, { tzOffset, value, shortIsoUtc, shortIsoLocal, longIsoLocal, formattedDate }, this.debug)
        return formattedDate
      } else {
        return 'n/a'
      }
    },
    getStatus (status) {
      if (!status) return
      const updatedStatus = status
        .split('_')
        .map((el) => toLowerCase(el))
        .map((el) => toFirstUpperCase(el))
        .toString()
        .replace(/,/g, ' ')
      return updatedStatus
    },
    toggleTableField (fieldName, tableName, index = 0, tableIdentity = '') {
      const fn = 'toggleTableField'
      const fieldsVar = tableName ? tableName + 'Fields' : 'fields'
      const optionalFieldsVar = tableName ? 'optional' + toFirstUpperCase(tableName) + 'Fields' : 'optionalFields'
      const optionalTableFieldNames = optionalFieldsVar.slice(0, -1) + 'Names'
      doLog(fn, { 'this[optionalFieldsVar][fieldName]': this[optionalFieldsVar][fieldName] }, this.debug)
      const isPresent = this[fieldsVar].filter((f) => f?.key === fieldName).length > 0
      doLog(fn, { fieldsVar, isPresent }, this.debug)
      if (isPresent) {
        let fieldIndex = -1
        this[fieldsVar].map((f, i) => {
          if (f.key === fieldName) fieldIndex = i
          return fieldIndex
        })
        if (fieldIndex > -1) this[fieldsVar].splice(fieldIndex, 1)
        this[optionalTableFieldNames].map((el) => {
          if (el.key === fieldName) el.checked = false
          return 'n/a'
        })
        let isOptionalColumnAdded
        this[optionalTableFieldNames].map((el) => {
          if (el.checked) isOptionalColumnAdded = true
          return 'n/a'
        })
        if (!isOptionalColumnAdded) this['optional' + tableIdentity + 'Columns'] = false
      } else {
        let insertAt = this[fieldsVar].length - index
        if (hasProp(this[optionalFieldsVar][fieldName], 'fieldIndex')) {
          insertAt = this[optionalFieldsVar].fieldIndex
        }
        this[fieldsVar].splice(insertAt, 0, this[optionalFieldsVar][fieldName])
        this[optionalTableFieldNames].map((el) => {
          if (el.key === fieldName) el.checked = true
          return 'n/a'
        })
        this['optional' + tableIdentity + 'Columns'] = true
      }
      this.refreshTable(tableName)
    },
    async scrollToCenterInRowInfo (rowId, entity, containerId) {
      await scrollToCenter(rowId, entity, containerId)
    },
    refreshTable (ref) {
      this.$refs[this[`${ref ? `${ref}T` : 't'}ableRef`]]?.refresh()
    },
    async clickFilter (key, type) {
      this[(type ? `${type}F` : 'f') + 'ilters'].map(async (filter) => {
        if (filter.key === key && !filter.pressDisabled) {
          filter.pressed = !filter.pressed
          await this.refresh()
        }
      })
    },
    async onReloadTrigger (newValue, oldValue) {
      const fn = 'onReloadTrigger'
      doLog(fn, { oldValue, newValue }, this.debug)
      if (newValue && newValue !== oldValue) {
        doLog(fn, 'refresh...', this.debug)
        await this.refresh()
      }
    },
    getCurrentLanguage () {
      const veEnvLoc = getVeEnvLoc()
      const currentLanguage = window.localStorage.getItem(
        `${veEnvLoc}/languageSelected`
      )
      return currentLanguage
    },
    async getAllSecurityContexts (module) {
      const fn = 'getAllSecurityContexts'
      const veEnvLoc = getVeEnvLoc()
      const mandatoryContexts = window.localStorage.getItem(`${veEnvLoc}/${module}MandatorySecurityContext`)
      if (mandatoryContexts && !this.refreshContexts) {
        this.mandatoryAndCustomSecurityContexts = JSON.parse(mandatoryContexts)
      } else {
        this.mandatoryAndCustomSecurityContexts = await ve.veapi.getAllDropdownSecurityContexts(module, this.debug)
        window.localStorage.setItem(`${veEnvLoc}/${module}MandatorySecurityContext`, JSON.stringify(this.mandatoryAndCustomSecurityContexts))
      }
      const moduleContext = window.localStorage.getItem(`${veEnvLoc}/${module}SecurityContext`)
      if (moduleContext && !this.refreshContexts) {
        this.securityContextTree = JSON.parse(moduleContext)
      } else {
        this.securityContextTree = await ve.veapi.getSecurityContexts(module, 'hierarchy', this.debug)
        window.localStorage.setItem(`${veEnvLoc}/${module}SecurityContext`, JSON.stringify(this.securityContextTree))
      }
      this.refreshContexts = false
      this.securityContextKeys = Object.keys(this.securityContextTree)
      this.securityContextKeys.shift()
      this.securityContextKeys.shift()
      doLog(fn, { mandatoryAndCustomSecurityContexts: this.mandatoryAndCustomSecurityContexts }, this.debug)
      // filter on rolesContextSecurityContexts, moved to veapi.js/ve-sat-api
      // this.filterOnRolesContextSecurityContexts()
      // todo: build module specific context menu
      const userProfile = getUserProfile(this.debug)
      doLog(fn, { userProfile }, this.debug) // todo: remove after testing
      const userOwner = userProfile.securityContext?.split('/')[0]
      const userSelectedSecurityContext = userProfile.selectedSecurityContext
      doLog(fn, { userOwner, docClass: this.docClass, userSelectedSecurityContext }, this.debug)
      if (!this.mandatoryAndCustomSecurityContexts.length) this.mandatoryAndCustomSecurityContexts = [`${userOwner}/${this.docClass}`]
      this.allOwners = getUniqueArray(this.mandatoryAndCustomSecurityContexts.map((c) => c.split('/')[0]))
      doLog(fn, { allOwners: this.allOwners }, this.debug)
      if (!this.currentOwner || !this.allOwners.includes(this.currentOwner)) this.currentOwner = this.allOwners[0]
      let context = this.getCurrentContext() // todo: review
      doLog(fn, { currentOwner: this.currentOwner, docClass: this.docClass, currentSecurityContext: this.currentSecurityContext, securityContext: this.securityContext, context }, this.debug)
      const defaultContext = `${this.currentOwner}/${this.docClass}`
      if (!context && userSelectedSecurityContext && userSelectedSecurityContext.startsWith(defaultContext)) context = userSelectedSecurityContext
      this.securityContext = context || defaultContext
      this.currentSecurityContext = context || defaultContext
      doLog(fn, { context, defaultContext, securityContext: this.securityContext, currentSecurityContext: this.currentSecurityContext }, this.debug)
      this.contextSelectorLastRefreshed = new Date()
    },
    getTabIndex (id, key = 'id') {
      let tabIndex
      this.tabs.map((tab, index) => {
        if (tab[key] === id) tabIndex = index + 1
        return index
      })
      return tabIndex
    },
    formatPercentage (num) {
      if (num > 100) num = 100
      return Math.round((num + Number.EPSILON) * 100) / 100
    },
    displayToast (variant, title, message, noAutoHide = false, autoHideDelay) {
      const params = { title, noAutoHide, variant }
      if (autoHideDelay) params.autoHideDelay = autoHideDelay
      this.$bvToast.toast(message, params)
    },
    toggleDebug () {
      const fn = 'toggleDebug'
      this.debug = !this.debug
      doLog(fn, { debug: this.debug }, true)
    },
    pollData () {
      this.pollDataId = setInterval(() => { this.getData() }, 5000)
    },
    selectedStates (type) {
      const fn = 'selectedStates'
      const pressedFilters = this[(type ? `${type}F` : 'f') + 'ilters'].filter((f) => f.pressed).map((f) => f.key)
      doLog(fn, { pressedFilters }, this.debug)
      return pressedFilters
    },
    getSelectedFilters (type) {
      const fn = 'getSelectedFilters'
      const pressedFilters = this[(type ? `${type}F` : 'f') + 'ilters'].filter(f => f.pressed).map(f => f.key)
      doLog(fn, { pressedFilters }, this.debug)
      return pressedFilters
    },
    getContractTxnIdCopyText (id) {
      return `contractTransactionId: ${id}`
    },
    getContractDefIdCopyText (id) {
      return `contractDefId: ${id}`
    },
    getContractIdCopyText (id) {
      return `contractId: ${id}`
    },
    getContractEventIdCopyText (id) {
      return `contractEventId: ${id}`
    },
    getCustomerIdCopyText (id) {
      return `customerId: ${id}`
    },
    getDeviceIdCopyText (id) {
      return `deviceId: ${id}`
    },
    getDeviceSerialNumberCopyText (id) {
      return `deviceSerialNumber: ${id}`
    },
    getEndPointIdCopyText (id) {
      return `endPointId: ${id}`
    },
    getOrchestrationEventIdCopyText (id) {
      return `orchestrationEventId: ${id}`
    },
    getSerialNoCopyText (id) {
      return `serialNo: ${id}`
    },
    getPhaseIdCopyText (id) {
      return `phaseId: ${id}`
    },
    getServiceEntityIdCopyText (id) {
      return `serviceEntityId: ${id}`
    },
    getUserIdCopyText (id) {
      return `userId: ${id}`
    },
    getCustomStatusCopyText (customStatus) {
      return `customStatus: ${customStatus}`
    },
    getAttributeFieldCopyText (index, value) {
      return `attributeField${index}: ${value}`
    },
    getEventDefIdCopyText (id) {
      return `eventDefId: ${id}`
    },
    getEventIdCopyText (id) {
      return `eventId: ${id}`
    },
    setOptionalFieldNames (tableName = '', dbg = false) {
      const fn = 'setOptionalFieldNames'
      const tofs = this[`optional${tableName}Fields`]
      const ofns = `optional${tableName}FieldNames`
      const tofns = this[ofns]
      doLog(fn, { ofns }, dbg)
      if (tofs) {
        const optionalFieldKeys = Object.keys(tofs)
        this[ofns] = optionalFieldKeys?.map((key, index) => ({ key, label: tofs[key].label || key, checked: tofns[index]?.key === key ? tofns[index].checked : false }))
      }
    },
    checkSearchKey () {
      const fn = 'checkSearchKey'
      this.clearIdParams()
      const segments = this.searchKey.split(',')
      const customStatusValues = []
      segments.map((segment) => {
        const [key, value] = segment.split(':').map((part) => part.trim())
        if (key && value) {
          doLog(fn, { key, value, segment, segments }, this.debug)
          // const isParamSupported = this.supportedParams?.includes(key)
          const isParamSupported = this.includesSupportedParams(key)
          if (key === 'customStatus' && isParamSupported) {
            customStatusValues.push(value)
          } else if (key.startsWith('attribute.') && isParamSupported) {
            this.attribute = `${key.replace('attribute.', '')}: ${value}`
          } else if (isParamSupported) {
            this[key] = value
          }
        }
        return segment
      })
      if (customStatusValues.length > 0) this.customStatus = customStatusValues.join(', ')
    },
    clearIdParams () {
      const params = this.supportedParams?.filter((param) => this[param])
      params?.map((param) => (this[param] = null))
      if (this.includesSupportedParams('attribute')) this.attribute = null
    },
    async onSearch () {
      const fn = 'onSearch'
      if (this.searchKey && this.searchKey.length > 2) {
        doLog(fn, { searchKey: this.searchKey }, this.debug)
        if (this.exclusiveStartKey) this.exclusiveStartKey = null
        this.searched = true
        this.getCount = true
        if (this.supportedParams?.length) this.checkSearchKey()
        await this.getData('onSearch')
        this.searched = false
      } else if (!this.searchKey) await this.clearSearch()
    },
    async clearSearch () {
      const fn = 'clearSearch'
      doLog(fn, { searchKey: this.searchKey }, this.debug)
      if (this.searchKey === 'debug=true') this.debug = true
      else if (this.searchKey === 'debug=false') this.debug = false
      this.searchKey = ''
      if (this.exclusiveStartKey) this.exclusiveStartKey = null
      if (this.supportedParams?.length) this.clearIdParams()
      this.searched = false
      this.getCount = true
      await this.getData('clearSearch')
    },
    getSearchKey () {
      const fn = 'getSearchKey'
      const segments = this.searchKey?.split(',')
      if (segments.length >= 1) {
        const lastSegment = segments[segments.length - 1]
        const [key] = lastSegment.split(':')
        // const trimmedKey = key ? key.trim() : ''
        const trimmedKey = this.getTrimmedKey(key)
        // const isParamSupported = this.supportedParams.includes(trimmedKey)
        const isParamSupported = this.includesSupportedParams(key)
        const searchKeyContainsId = trimmedKey && isParamSupported
        const searchKey = searchKeyContainsId ? '' : lastSegment.trim()
        doLog(fn, { key, trimmedKey, isParamSupported, searchKeyContainsId, searchKey }, this.debug)
        return searchKey
      }
    },
    searchKeyContainsId () {
      const splitSearchKey = this.searchKey.split(':')
      // const isParamSupported = this.supportedParams?.includes(splitSearchKey[0])
      const isParamSupported = this.includesSupportedParams(splitSearchKey[0])
      return splitSearchKey.length > 1 && isParamSupported
    },
    getTrimmedKey (key) {
      const fn = 'getTrimmedKey'
      let trimmedKey = key ? key.trim() : ''
      if (trimmedKey.startsWith('attribute.')) trimmedKey = 'attribute'
      doLog(fn, { key, trimmedKey }, this.debug)
      return trimmedKey
    },
    includesSupportedParams (key) {
      const fn = 'includesSupportedParams'
      const trimmedKey = this.getTrimmedKey(key)
      const isAttributeKey = trimmedKey === 'attribute' // = this.supportedParams.filter(sp => sp.startsWith('attribute.')).length > 0
      const isParamSupported = this.supportedParams?.includes(trimmedKey)
      const doesIncludeSupportedParams = isParamSupported || isAttributeKey
      doLog(fn, { key, trimmedKey, isAttributeKey, isParamSupported, doesIncludeSupportedParams, supportedParams: this.supportedParams }, this.debug)
      return doesIncludeSupportedParams
    },
    toggleRowPreview (row, type, uniqueId = 'id', item = 'previewRow', parentId = this.tableRef) {
      const fn = 'toggleRowPreview'
      doLog(fn, { type, uniqueId, item, parentId, rowItem: row.item[item] }, this.debug)
      row.item[item] = !(row.item[item] || false)
      doLog(fn, { item }, this.debug)
      row.toggleDetails()
      if (row.item[item]) {
        this.scrollToCenterInRowInfo(row.item[uniqueId], row.item[item], parentId)
      }
    },
    decLoadHeadHtml (label) {
      const absLoad = Math.abs(this.decLoadTotal)
      const loadInPixel = parseInt(this.loadHeadPixFactor * absLoad) // todo: calc factor
      const loadText = roundTo(absLoad || 0, 1) + 'kW'
      let html =
        '<span class="text-right pr-3 mr-3">' + label + '&nbsp;&nbsp;<br />'
      html += '<span class="mr-0 float-right text-center text-white"'
      html +=
        ' style="height: 22px; background-color: orange; border: solid orange 2px; width: ' +
        loadInPixel +
        'px;"'
      html += ' v-b-tooltip.hover title="' + loadText + '">'
      const minLoadToShowInSideBar = 20 // was 2.2
      if (absLoad >= minLoadToShowInSideBar) html += loadText
      html += '</span>'
      if (absLoad < minLoadToShowInSideBar) html += '<span class="ml-2" style="color: orange;">' + loadText + '</span>'
      html += '</span>'
      return html
    },
    decLoadHtml (decLoad) {
      const absLoad = Math.abs(decLoad)
      const loadInPixel = parseInt(this.loadHeadPixFactor * absLoad) // todo: calc factor
      const loadText = roundTo(absLoad || 0, 1) + 'kW'
      let html = '<span class="mr-0 float-right text-center text-white"'
      html += ' style="height: 22px; background-color: orange; border: solid orange 2px; width: ' + loadInPixel + 'px;"'
      html += ' v-b-tooltip.hover title="' + loadText + '">'
      const minLoadToShowInSideBar = 20 // was 0.8
      if (absLoad >= minLoadToShowInSideBar) html += loadText
      html += '</span>'
      if (absLoad < minLoadToShowInSideBar) html += '<span class="mr-2" style="color: orange;">' + loadText + '</span>'
      return html
    },
    incLoadHeadHtml (label) {
      const absLoad = Math.abs(this.incLoadTotal)
      const loadInPixel = parseInt(this.loadHeadPixFactor * absLoad) // todo: calc factor
      const loadText = roundTo(absLoad || 0, 1) + 'kW'
      let html = '<span class="text-left">&nbsp;&nbsp;' + label + '<br />'
      html += '<span class="ml-0 float-left text-center text-white"'
      html += ' style="height: 22px; background-color: grey; border: solid grey 2px; width: ' + loadInPixel + 'px;"'
      html += ' v-b-tooltip.hover title="' + loadText + '">'
      const minLoadToShowInSideBar = 20 // was 2.2
      if (absLoad >= minLoadToShowInSideBar) html += loadText
      html += '</span>'
      if (absLoad < minLoadToShowInSideBar) html += '<span class="ml-2" style="color: grey;">' + loadText + '</span>'
      html += '</span>'
      return html
    },
    incLoadHtml (incLoad) {
      const absLoad = Math.abs(incLoad)
      const loadInPixel = parseInt(this.loadHeadPixFactor * absLoad) // todo: calc factor
      const loadText = roundTo(absLoad || 0, 1) + 'kW'
      let html = '<span class="ml-0 float-left text-center text-white"'
      html += ' style="height: 22px; background-color: grey; border: solid grey 2px; width: ' + loadInPixel + 'px;"'
      html += ' v-b-tooltip.hover title="' + loadText + '">'
      const minLoadToShowInSideBar = 20 // was 0.8
      if (absLoad >= minLoadToShowInSideBar) html += loadText
      html += '</span>'
      if (absLoad < minLoadToShowInSideBar) html += '<span class="ml-2" style="color: grey;">' + loadText + '</span>'
      return html
    },
    getShortContext (context) {
      try {
        // addToLog('getShortContext:context: ' + context, this.debug)
        const contextParts = context.split('/')
        const joinText = '/&#8203;'
        return contextParts.slice(Math.max(contextParts.length - 4, 0)).join(joinText)
      } catch (e) {
        console.debug('Failed to create shortContext for: ', context)
        console.error(e)
      }
      return context
    },
    onToggleOptionalField (field) {
      this.toggleTableField(field)
      this.refreshTable()
    },
    filterTable (row, filter) {
      const rowValues = Object.values(row).join('|')
      const filterValues = filter.split(',').map((f) => f.trim()).filter((f) => f !== '')
      const result = filterValues.filter((f) => rowValues.includes(f))
      return result.length > 0
    },
    onRowOpenChange (row, idType = 'id') {
      if (row.item.previewRow) {
        this.openRows.push(row.item[idType])
      } else if (this.openRows.includes(row.item[idType])) {
        this.openRows = this.openRows.filter((ce) => ce[idType] === row.item[idType])
      }
    },
    checkAndOpenRows (idType = 'id') {
      if (this.openRows.length) {
        this[this.tableRef + 'Items'].map((ce, index) => {
          if (this.openRows.includes(ce[idType])) {
            this[this.tableRef + 'Items'][index].previewRow = true
            this[this.tableRef + 'Items'][index]._showDetails = true
          }
          return index
        })
      }
    },
    parseContractDefMetrics (contractDef) {
      const fn = 'parseContractDefMetrics'
      // "contractParameters": [{ "algorithm": "Custom", "objectIdentifier": "CPA",
      // "parameters": [
      //    { "key": "test1", "value": "value1" },
      //    { "key": "test2", "value": "1 + {{metric.price}} - {{metric.lowerPrice}}" }
      //  ],
      //  "executeIfTrue": "{{metric.testingTrueOrFalse}}" }],
      //  "actions": [{ "algorithm": "notifyCustomer",
      //  "parameters": [
      //    { "key": "msgText", "value": "some message: {{metric.msgText}}"},...
      //  ],
      //  "executeIfTrue": "{{metric.executeActionIfTrue}}"
      // }]
      // { <contractDefIdvVersionA>: { metrics: { metricA: '', metricB: '', ... }], <contractDefIdvVersionB>: {},... }
      const contractDefIdVersion = contractDef.contractDefId + 'v' + contractDef.version
      doLog(fn, { contractDefIdVersion }, this.debug)
      const metrics = {}
      const gpParameters = contractDef.globalParameters?.flatMap(gp => gp.parameters) || []
      const cpParameters = contractDef.contractParameters?.flatMap(cp => cp.parameters) || []
      const acParameters = contractDef.actions?.flatMap(ac => ac.parameters) || []
      const metricNames = []
      doLog(fn, { gpParameters, cpParameters, acParameters }, this.debug)
      for (const parameter of [...gpParameters, ...cpParameters, ...acParameters]) {
        const value = parameter?.value
        if (value && `${value}`.includes('{{metric.')) {
          const mStarts = getIndicesOf('{{metric.', value)
          for (const mStart of mStarts) {
            const rest = value.slice(mStart + 9)
            metricNames.push(rest.split('}}')[0])
          }
        }
      }
      const key = 'executeIfTrue'
      const gpExecuteIfTrue = this.filterAndMap(contractDef.globalParameters, key)
      const ccExecuteIfCriteriaTrue = contractDef.contractCriteria?.executeIfCriteriaTrue || ''
      const cpExecuteIfTrue = this.filterAndMap(contractDef.contractParameters, key)
      const acExecuteIfTrue = this.filterAndMap(contractDef.actions, key)
      for (const executeIfTrue of [...gpExecuteIfTrue, ...cpExecuteIfTrue, ...acExecuteIfTrue, ccExecuteIfCriteriaTrue]) {
        if (`${executeIfTrue}`.includes('{{metric.')) {
          const mStarts = getIndicesOf('{{metric.', executeIfTrue)
          for (const mStart of mStarts) {
            const rest = executeIfTrue.slice(mStart + 9)
            metricNames.push(rest.split('}}')[0])
          }
          doLog(fn, { metricNames }, this.debug)
        }
      }
      const acExecutionDelay = this.filterAndMap(contractDef.actions, 'executionDelay')
      for (const executionDelay of [...acExecutionDelay]) {
        if (`${executionDelay}`?.includes('{{metric.')) {
          const mStarts = getIndicesOf('{{metric.', executionDelay)
          for (const mStart of mStarts) {
            const rest = executionDelay.slice(mStart + 9)
            metricNames.push(rest.split('}}')[0])
          }
          doLog(fn, { metricNames }, this.debug)
        }
      }
      for (const metricName of getUniqueArray(metricNames)) {
        metrics[metricName] = ''
      }
      doLog(fn, { metrics }, this.debug)
      if (!this.contractDefMetrics[contractDefIdVersion]) this.contractDefMetrics[contractDefIdVersion] = {}
      this.contractDefMetrics[contractDefIdVersion].metrics = metrics
      doLog(fn, { contractDefMetrics: this.contractDefMetrics }, this.debug)
    },
    filterAndMap (object, key) {
      const response = object?.filter((ac) => ac[key]).map((ac) => ac[key])
      return response || []
    },
    getUiConfig (keyOrIndex, pageIndex) {
      // addToLog(`getUiConfig:keyOrIndex: ${keyOrIndex}, pageIndex: ${pageIndex}`, true)
      // addToLog(`getUiConfig:this.configType: ${this.configType}`, true)
      // console.log(`getUiConfig:this.configType: ${this.configType}, keyOrIndex: ${keyOrIndex}, pageIndex: ${pageIndex}`)
      let page
      let configs
      let foundItem
      switch (this.configType) {
        case 'contractConfig':
          return typeof keyOrIndex === 'number' ? this.allAttributes[keyOrIndex] : this.allAttributes.find((attribute) => attribute.key === keyOrIndex)
        case 'sendNotification':
          return this.notificationTemplateJSON?.values[pageIndex]?.parameters?.find((param) => param.key === keyOrIndex)
        case 'contractDefWizard':
          page = this.contractDef.uiConfig[this.configType][pageIndex]
          return page.fields?.find((field, index) => field.key === keyOrIndex || index === keyOrIndex)
        case 'dataFilePage1':
          return this.menuItem.pageTemplate.tableRows[pageIndex]?.uiConfig?.find((param) => param.key === keyOrIndex)
        default:
          configs = this.contractDef?.uiConfig ? this.contractDef.uiConfig[this.configType] : []
          foundItem = configs?.find((config) => config.key === keyOrIndex) || {}
          // addToLog(`getUiConfig:foundItem: ${JSON.stringify(foundItem)}`, true)
          return foundItem
      }
    },
    showSeconds (keyOrIndex, pageIndex) {
      const uiConfig = this.getUiConfig(keyOrIndex, pageIndex)
      const valueIncludesSeconds =
        uiConfig.value !== undefined || uiConfig.value !== null
          ? this.valueIncludesSeconds(keyOrIndex, pageIndex)
          : false
      const inputFormatIncludesSeconds = uiConfig.inputFormat
        ? this.inputFormatIncludesSeconds(keyOrIndex, pageIndex)
        : false
      const showSeconds = inputFormatIncludesSeconds || valueIncludesSeconds
      return showSeconds
    },
    valueIncludesSeconds (keyOrIndex, pageIndex) {
      const uiConfig = this.getUiConfig(keyOrIndex, pageIndex)
      if (typeof uiConfig.value === 'string') return uiConfig.value.split(':').length === 3
      return false
    },
    inputFormatIncludesSeconds (keyOrIndex, pageIndex) {
      const uiConfig = this.getUiConfig(keyOrIndex, pageIndex)
      if (typeof uiConfig.inputFormat === 'string') return uiConfig.inputFormat.toLowerCase().includes('ss')
      return false
    },
    setDateTime (timeUnit, pageIndex) {
      this[`${timeUnit}sOfDateTime`][pageIndex]?.map((timeValue, index) => {
        const key = this.getKey(index, pageIndex)
        const uiConfig = this.getUiConfig(key || index, pageIndex)
        const dateTimeExists = this.datesOfDateTime[pageIndex][index] && this.timesOfDateTime[pageIndex][index]
        if (uiConfig && dateTimeExists) {
          const time = this.timesOfDateTime[pageIndex][index]
          const splittedTime = time.split(':')
          const selectedTzOffset = this.timeZonesOfDateTime[pageIndex][index] ? this.getTzOffset(this.timeZonesOfDateTime[pageIndex][index]) : null
          const tzOffset = selectedTzOffset || this.tzOffset
          const dateTime = this.datesOfDateTime[pageIndex][index] + 'T' + (splittedTime.length === 3 ? time : `${time}:00`) + tzOffset
          let utcDateTime = moment.utc(dateTime).toISOString()
          const splittedUtcDateTime = utcDateTime.split('T')
          utcDateTime = splittedUtcDateTime[0] + ' ' + (this.showSeconds(key, pageIndex) ? splittedUtcDateTime[1].slice(0, -5) : splittedUtcDateTime[1].slice(0, -8))
          const keyOrIndex = this.getKeyOrIndex(key, index)
          this.setValue(keyOrIndex, utcDateTime, pageIndex)
        }
        return index
      })
    },
    getPageName (pageIndex) {
      switch (this.configType) {
        case 'sendNotification':
          return this.notificationTemplateJSON.values[pageIndex].value
        case 'contractDefWizard':
          return this.contractDef.uiConfig[this.configType][pageIndex].page
        case 'dataFilePage1':
          return this.menuItem.pageTemplate.tableRows[pageIndex]
            .column1HeaderText
      }
    },
    getTzOffset (tzAbbr) {
      switch (tzAbbr) {
        case 'AWST':
          return '+08:00'
        case 'UTC':
          return '+00:00'
        default:
          return `${new Date()}`.split('GMT')[1].slice(0, 3) + ':' + `${new Date()}`.split('GMT')[1].slice(3, 5)
      }
    },
    getKeys (pageIndex) {
      switch (this.configType) {
        case 'invokeContractUI':
          return this.metrics
        case 'contractConfig':
          return this.attributes
        case 'contractDefWizard':
          return this[`${this.getKeyType(pageIndex)?.toLowerCase()}s`]
        case 'sendNotification':
          return this[`${this.getKeyType(pageIndex)?.toLowerCase()}s`]
        case 'dataFilePage1':
          return this[`${this.getKeyType(pageIndex)?.toLowerCase()}s`]
      }
    },
    getKey (index, pageIndex) {
      switch (this.configType) {
        case 'invokeContractUI':
          return this.metrics[index]
        case 'contractConfig':
          return this.attributes[index]
        case 'contractDefWizard':
          return this[`${this.getKeyType(pageIndex)?.toLowerCase()}s`][index]
        case 'sendNotification':
          return this[`${this.getKeyType(pageIndex)?.toLowerCase()}s`][index]
        case 'dataFilePage1':
          return this[`${this.getKeyType(pageIndex)?.toLowerCase()}s`][index]
      }
    },
    getKeyOrIndex (key, index) {
      if (this.configType === 'invokeContractUI') return key
      if (['contractConfig', 'contractDefWizard', 'sendNotification', 'dataFilePage1'].includes(this.configType)) return index
    },
    setValue (keyOrIndex, value, pageIndex) {
      let template
      let templateIndex
      switch (this.configType) {
        case 'invokeContractUI':
          this.contractDefMetrics[this.contractDefKey].metrics[keyOrIndex] =
            value
          break
        case 'contractConfig':
          this.editedAttributes[keyOrIndex] = value
          break
        case 'contractDefWizard':
          this.contractDef.uiConfig[this.configType][pageIndex].fields[keyOrIndex].value = value
          break
        case 'sendNotification':
          template = this.getNotificationTemplate()
          templateIndex = this.getTemplateIndex(template)
          this.notificationTemplateJSON.values[templateIndex].parameters[keyOrIndex].value = value
          break
        case 'dataFilePage1':
          this.clickedRow.uiConfig[keyOrIndex].value = value
          break
      }
    },
    setTime (timeUnit, pageIndex) {
      const pageTimes = this[`${timeUnit}sOfTime`][pageIndex]
      pageTimes?.map((timeValue, index) => {
        const key = this.getKey(index, pageIndex)
        const uiConfig = this.getUiConfig(key || index, pageIndex)
        if (uiConfig && this.timesOfTime[pageIndex][index]) {
          const time = this.timesOfTime[pageIndex][index]
          const selectedTzOffset = this.timeZonesOfTime[pageIndex][index] ? this.getTzOffset(this.timeZonesOfTime[pageIndex][index]) : null
          const tzOffset = selectedTzOffset || this.tzOffset
          const timeformat = `${time}:00` + tzOffset
          let utcTime = moment.utc(timeformat, 'HH:mm:ssZ').toISOString()
          const splittedUtcTime = utcTime.split('T')
          utcTime = this.showSeconds(key, pageIndex) ? splittedUtcTime[1].slice(0, -5) : splittedUtcTime[1].slice(0, -8)
          const keyOrIndex = this.getKeyOrIndex(key, index)
          this.setValue(keyOrIndex, utcTime, pageIndex)
        }
      })
    },
    getTimeInDesiredForm (time, convertData) {
      if (convertData) {
        switch (convertData) {
          case 'convertToSeconds':
            return moment.duration(time).asSeconds()
          case 'convertToMinutes':
            return moment.duration(time).asMinutes()
        }
      }
      return time
    },
    setTimerValue (timeUnit, pageIndex) {
      this[`${timeUnit}sTimer`][pageIndex]?.map((timeValue, index) => {
        const key = this.getKey(index, pageIndex)
        const uiConfig = this.getUiConfig(key || index, pageIndex)
        if (uiConfig) {
          const secondValueExists = this.secondValueExists(index, pageIndex)
          const secondsExist = this.inputFormatIncludesSeconds(key, pageIndex)
            ? secondValueExists
            : true
          if (
            this.hourValueExists(index, pageIndex) &&
            this.minuteValueExists(index, pageIndex) &&
            secondsExist
          ) {
            const hours = this.getHours(index, pageIndex)
            const minutes = this.getMinutes(index, pageIndex)
            const seconds =
              secondsExist && secondValueExists
                ? this.getSeconds(index, pageIndex)
                : '00'
            let time = hours + ':' + minutes + ':' + seconds
            const timeInDesiredForm = uiConfig.convertData
              ? this.getTimeInDesiredForm(time, uiConfig.convertData)
              : false
            time = uiConfig.convertData ? timeInDesiredForm : this.showSeconds(key, pageIndex) ? time : hours + ':' + minutes
            const keyOrIndex = this.getKeyOrIndex(key, index)
            this.setValue(keyOrIndex, time, pageIndex)
          } else {
            const keyOrIndex = this.getKeyOrIndex(key, index)
            this.setValue(keyOrIndex, null, pageIndex)
          }
        }
      })
    },
    getHours (index, pageIndex) {
      return `${this.hoursTimer[pageIndex][index]}`.length === 1
        ? `0${this.hoursTimer[pageIndex][index]}`
        : `${this.hoursTimer[pageIndex][index]}`
    },
    getMinutes (index, pageIndex) {
      return `${this.minutesTimer[pageIndex][index]}`.length === 1
        ? `0${this.minutesTimer[pageIndex][index]}`
        : `${this.minutesTimer[pageIndex][index]}`
    },
    getSeconds (index, pageIndex) {
      return `${this.secondsTimer[pageIndex][index]}`.length === 1
        ? `0${this.secondsTimer[pageIndex][index]}`
        : `${this.secondsTimer[pageIndex][index]}`
    },
    hourValueExists (index, pageIndex) {
      return (
        this.hoursTimer[pageIndex][index] !== null &&
        this.hoursTimer[pageIndex][index] !== undefined &&
        this.hoursTimer[pageIndex][index] !== ''
      )
    },
    minuteValueExists (index, pageIndex) {
      return (
        this.minutesTimer[pageIndex][index] !== null &&
        this.minutesTimer[pageIndex][index] !== undefined &&
        this.minutesTimer[pageIndex][index] !== ''
      )
    },
    secondValueExists (index, pageIndex) {
      return (
        this.secondsTimer[pageIndex][index] !== null &&
        this.secondsTimer[pageIndex][index] !== undefined &&
        this.secondsTimer[pageIndex][index] !== ''
      )
    },
    valueExists (value) {
      const valueExists = value !== null && value !== undefined && value !== ''
      return valueExists
    },
    getTimezones (keyOrIndex, pageIndex) {
      const uiConfig = this.getUiConfig(keyOrIndex, pageIndex)
      if (typeof uiConfig?.timezone?.timezones === 'object') return Object.keys(uiConfig.timezone.timezones)
      return []
    },
    getValue (keyOrIndex, pageIndex) {
      switch (this.configType) {
        case 'invokeContractUI':
          return this.contractDefMetrics[this.contractDefKey].metrics[
            keyOrIndex
          ]
        case 'contractConfig':
          return this.editedAttributes[keyOrIndex]
        case 'contractDefWizard':
          return this.contractDef.uiConfig[this.configType][pageIndex]?.fields[
            keyOrIndex
          ]?.value
        case 'sendNotification':
          return this.notificationTemplateJSON?.values[pageIndex]?.parameters[
            keyOrIndex
          ]?.value
        case 'dataFilePage1':
          return this.clickedRow.uiConfig[keyOrIndex]?.value
      }
    },
    checkParamValues (pageIndex) {
      const keys = this.getKeys(pageIndex)
      keys?.map((key, index) => this.checkParamValue(key, index, pageIndex))
    },
    checkParamValue (key, index, pageIndex) {
      const uiConfig = this.getUiConfig(key || index, pageIndex)
      const keyOrIndex = this.getKeyOrIndex(key, index)
      const value = this.getValue(keyOrIndex, pageIndex)
      const valueDoesNotExist = !value && value !== 0 && value !== false
      this.areParamValuesValid[pageIndex][index] = true
      if (uiConfig?.key) {
        const constraintExists =
          typeof uiConfig.validations?.min === 'number' ||
          typeof uiConfig.validations?.max === 'number' ||
          typeof uiConfig.validations?.lessThan ||
          typeof uiConfig.validations?.greaterThan
        if (this.configType === 'contractConfig')
          this.checkAttributeType(index, key)
        if (uiConfig.validations?.isRequired && valueDoesNotExist) {
          const keyType = this.getKeyType(pageIndex)
          this.areParamValuesValid[pageIndex][index] = false
          this.invalidFeedback[pageIndex][index] =
            uiConfig.validations.inputValidationText?.isRequired ||
            `Required ${keyType} field is missing.`
        } else if (constraintExists)
          this.checkParamValueForConstraints(uiConfig, value, index, pageIndex)
        else if (this.checkFieldControl(uiConfig, 'timeDatePicker'))
          this.checkParamValueForDTPicker(uiConfig, index, pageIndex)
        if (this.areParamValuesValid[pageIndex][index])
          this.invalidFeedback[pageIndex][index] = ''
        if (this.configType === 'sendNotification') this.setParamValue(uiConfig)
      }
    },
    checkParamValueForConstraints (uiConfig, value, index, pageIndex) {
      switch (uiConfig.type || uiConfig.validations?.type) {
        case 'text':
          this.handleStringConstraints(uiConfig, value, index, pageIndex)
          break
        case 'number':
          this.handleNumberConstraints(uiConfig, value, index, pageIndex)
          break
      }
    },
    handleStringConstraints (uiConfig, value, index, pageIndex) {
      const keyType = this.getKeyType(pageIndex)?.toLowerCase()
      if (
        typeof +uiConfig.validations?.min === 'number' &&
        value.length < +uiConfig.validations?.min
      ) {
        this.areParamValuesValid[pageIndex][index] = false
        this.invalidFeedback[pageIndex][index] =
          uiConfig.validations.inputValidationText?.min ||
          `${keyType} is less than the minimum length of ${uiConfig.validations?.min}`
      } else if (
        typeof +uiConfig.validations?.max === 'number' &&
        value.length > +uiConfig.validations?.max
      ) {
        this.areParamValuesValid[pageIndex][index] = false
        this.invalidFeedback[pageIndex][index] =
          uiConfig.validations.inputValidationText?.max ||
          `${keyType} exceeds maximum length of ${uiConfig.validations?.max}`
      }
    },
    handleNumberConstraints (uiConfig, value, index, pageIndex) {
      const keyType = this.getKeyType(pageIndex)?.toLowerCase()
      const greaterThanIndexOf = this[`${keyType}s`]?.indexOf(
        uiConfig.validations?.greaterThan
      )
      const lessThanIndexOf = this[`${keyType}s`]?.indexOf(
        uiConfig.validations?.lessThan
      )
      const greaterThanKeyOrIndex = this.getKeyOrIndex(
        uiConfig.validations?.greaterThan,
        greaterThanIndexOf
      )
      const lessThanKeyOrIndex = this.getKeyOrIndex(
        uiConfig.validations?.lessThan,
        lessThanIndexOf
      )
      if (
        typeof +uiConfig.validations?.min === 'number' &&
        value < +uiConfig.validations?.min
      ) {
        this.areParamValuesValid[pageIndex][index] = false
        this.invalidFeedback[pageIndex][index] =
          uiConfig.validations.inputValidationText?.min ||
          `${keyType} is less than the minimum of ${uiConfig.validations?.min}`
      } else if (
        typeof +uiConfig.validations?.max === 'number' &&
        value > +uiConfig.validations?.max
      ) {
        this.areParamValuesValid[pageIndex][index] = false
        this.invalidFeedback[pageIndex][index] =
          uiConfig.validations.inputValidationText?.max ||
          `${keyType} exceeds maximum of ${uiConfig.validations?.max}`
      } else if (
        typeof +uiConfig.validations?.greaterThan === 'number' &&
        value <= +this.getValue(greaterThanKeyOrIndex, pageIndex)
      ) {
        this.areParamValuesValid[pageIndex][index] = false
        this.invalidFeedback[pageIndex][index] =
          uiConfig.validations.inputValidationText.greaterThan
      } else if (
        typeof +uiConfig.validations?.lessThan === 'number' &&
        value >= +this.getValue(lessThanKeyOrIndex, pageIndex)
      ) {
        this.areParamValuesValid[pageIndex][index] = false
        this.invalidFeedback[pageIndex][index] =
          uiConfig.validations.inputValidationText.lessThan
      } else if (
        value > +this.getValue(greaterThanKeyOrIndex, pageIndex) &&
        +this.getValue(greaterThanKeyOrIndex, pageIndex) >
          +uiConfig.validations?.min &&
        +this.getValue(greaterThanKeyOrIndex, pageIndex) <
          +uiConfig.validations?.max
      ) {
        this.areParamValuesValid[pageIndex][greaterThanIndexOf] = true
        this.invalidFeedback[pageIndex][greaterThanIndexOf] = ''
      } else if (
        value < +this.getValue(lessThanKeyOrIndex, pageIndex) &&
        +this.getValue(lessThanKeyOrIndex, pageIndex) >
          +uiConfig.validations?.min &&
        +this.getValue(lessThanKeyOrIndex, pageIndex) <
          +uiConfig.validations?.max
      ) {
        this.areParamValuesValid[pageIndex][lessThanIndexOf] = true
        this.invalidFeedback[pageIndex][lessThanIndexOf] = ''
      }
    },
    getKeyType (pageIndex) {
      switch (this.configType) {
        case 'invokeContractUI':
          return 'Metric'
        case 'contractConfig':
          return 'Attribute'
        case 'sendNotification':
          return this.getPageName(pageIndex)
        case 'contractDefWizard':
          return this.getPageName(pageIndex)
        case 'dataFilePage1':
          return this.getPageName(pageIndex)
      }
    },
    getDate (offset) {
      const date = offset ? moment().utcOffset(offset).format('YYYY-MM-DD') : moment().format('YYYY-MM-DD')
      return date
    },
    checkParamValueForDTPicker (uiConfig, index, pageIndex) {
      const dtExists = this.datesOfDateTime[pageIndex][index] && this.timesOfDateTime[pageIndex][index]
      if (dtExists) {
        this.areParamValuesValid[pageIndex][index] = this.isDateTimeValid(index, uiConfig, pageIndex)
        if (!this.areParamValuesValid[pageIndex][index])
          this.invalidFeedback[pageIndex][index] = this.$t('Please select future time.')
      } else {
        const keyType = this.getKeyType(pageIndex)
        this.areParamValuesValid[pageIndex][index] = false
        this.invalidFeedback[pageIndex][index] = this.$t(`Required ${keyType} is missing.`)
      }
    },
    isDateTimeValid (index, uiConfig, pageIndex) {
      const isDTValid = this.isDateTimeInFuture(
        this.datesOfDateTime[pageIndex][index],
        this.timesOfDateTime[pageIndex][index]
      )
      const tzOffset = this.timeZonesOfDateTime[pageIndex][index]
        ? uiConfig.timezone.timezones[
            this.timeZonesOfDateTime[pageIndex][index]
          ]
        : ''
      const isSelectedTzDtValid = this.isSelectedTzDtValid(
        index,
        tzOffset,
        pageIndex
      )
      const isUtcDtValid = this.isUtcDtValid(index, pageIndex)
      const isTimeZoneDTValid = this.timeZonesOfDateTime[pageIndex][index]
        ? isSelectedTzDtValid
        : isUtcDtValid
      const isDateTimeValid = uiConfig.timezone ? isTimeZoneDTValid : isDTValid
      return isDateTimeValid
    },
    isDateTimeInFuture (date, time, dateToCompare = this.getDate(), timeToCompare = this.presentTime()) {
      let isDateTimeInFuture = true
      if (date === dateToCompare) isDateTimeInFuture = time > timeToCompare
      return isDateTimeInFuture
    },
    presentTime () {
      const time = moment().format('HH:mm')
      return time
    },
    isSelectedTzDtValid (index, tzOffset, pageIndex) {
      return this.timeZonesOfDateTime[pageIndex][index]
        ? this.isDateTimeInFuture(
            this.datesOfDateTime[pageIndex][index],
            this.timesOfDateTime[pageIndex][index],
            moment().utcOffset(tzOffset).format('YYYY-MM-DD'),
            moment().utcOffset(tzOffset).format('HH:mm')
          )
        : false
    },
    isUtcDtValid (index, pageIndex) {
      return this.isDateTimeInFuture(
        this.datesOfDateTime[pageIndex][index],
        this.timesOfDateTime[pageIndex][index],
        moment().utc().format('YYYY-MM-DD'),
        moment().utc().format('HH:mm')
      )
    },
    setDefaultValues (pageIndex) {
      if (this.configType === 'dataFilePage1') {
        this.clickedRow?.uiConfig.map((config, index) => {
          this.setDefaultValue(config, config.key, index, pageIndex)
        })
      } else {
        const keyType = this.getKeyType(pageIndex)?.toLowerCase()
        const pageKeys = this[`${keyType}s`]
        pageKeys?.map((key, index) => {
          this.contractDef.uiConfig?.[this.configType]?.map((config) => {
            if (this.configType === 'contractDefWizard') {
              const field = config.fields[index]
              if (!field) return
              this.setDefaultValue(field, key, index, pageIndex)
            } else this.setDefaultValue(config, key, index, pageIndex)
          })
        })
      }
    },
    setDefaultValue (config, key, index, pageIndex) {
      const defaultTimezoneExists = config.timezone?.defaultTimezone
      const isTempInput = this.checkFieldControl(config, 'temperatureInput')
      const minMaxExists = this.valueExists(config.validations?.min) && this.valueExists(config.validations?.max)
      const minMaxTempExists = isTempInput && minMaxExists
      const keyExists = config.key === key || config.key === key?.split('.')[0]
      if (keyExists && (config.value || defaultTimezoneExists || minMaxTempExists || this.checkFieldControl(config, 'checkBox'))) {
        const keyOrIndex = this.getKeyOrIndex(key, index)
        switch (config.fieldControlSat || config.fieldControl) {
          case 'svg':
            if (config.value) this.setValue(keyOrIndex, config.value, pageIndex)
            draw.setInnerHtml(this.svgDivId, config.value)
            break
          case 'temperatureInput':
            if (config.value) this.setValue(keyOrIndex, config.value, pageIndex)
            else {
              const avgTemp = (config.validations?.min + config.validations?.max) / 2
              this.setValue(keyOrIndex, Math.round(avgTemp), pageIndex)
            }
            break
          case 'timePicker':
            this.setDefaultTPickerValue(config, index, pageIndex)
            break
          case 'timer':
            this.setDefaultTimerValue(config, index, pageIndex)
            break
          case 'timeDatePicker':
            this.setDefaultTDPickerValue(config, index, pageIndex)
            break
          case 'checkBox':
            if (!config.value) config.value = config.options || []
            const textArray = config.value.filter((option) => option.value === true).map((option) => option.text)
            const valueArray = config.value.map((option) => option.value)
            this.checkBox[pageIndex][index] = textArray
            // todo: review if break is needed here
          default:
            this.setValue(keyOrIndex, config.value, pageIndex)
        }
      }
    },
    setDefaultTimerValue (config, index, pageIndex) {
      const splittedTime = config.value.split(':')
      this.hoursTimer[pageIndex][index] = splittedTime[0]
      this.setTimerValue('hour', pageIndex)
      this.minutesTimer[pageIndex][index] = splittedTime[1]
      this.setTimerValue('minute', pageIndex)
      const includesSeconds =
        typeof config.inputFormat === 'string'
          ? config.inputFormat.toLowerCase().includes('ss')
          : false
      if (
        includesSeconds ||
        (!config.inputFormat && splittedTime.length === 3)
      ) {
        this.secondsTimer[pageIndex][index] = splittedTime[2]
        this.setTimerValue('second', pageIndex)
      }
    },
    setDefaultTDPickerValue (config, index, pageIndex) {
      if (config.value) {
        const splittedDateTime = config.value.split(' ')
        this.datesOfDateTime[pageIndex][index] = splittedDateTime[0]
        this.setDateTime('date', pageIndex)
        this.timesOfDateTime[pageIndex][index] = splittedDateTime[1]
        this.setDateTime('time', pageIndex)
      }
      if (config.timezone?.defaultTimezone) {
        this.timeZonesOfDateTime[pageIndex][index] =
          config.timezone.defaultTimezone
        this.setDateTime('timeZone', pageIndex)
      }
    },
    setDefaultTPickerValue (config, index, pageIndex) {
      if (config.value) {
        this.timesOfTime[pageIndex][index] = config.value
        this.setTime('time', pageIndex)
      }
      if (config.timezone?.defaultTimezone) {
        this.timeZonesOfTime[pageIndex][index] = config.timezone.defaultTimezone
        this.setTime('timeZone', pageIndex)
      }
    },
    areRequiredParamsSet (pageIndex) {
      let areRequiredParamsSet = true
      const keyType = this.getKeyType(pageIndex)?.toLowerCase()
      this[`${keyType}s`]?.map((key, index) => {
        const uiConfig = this.getUiConfig(key, pageIndex)
        if (uiConfig?.validations?.isRequired) {
          const keyOrIndex = this.getKeyOrIndex(key, index)
          const value = this.getValue(keyOrIndex, pageIndex)
          if (!value && value !== 0 && value !== false) areRequiredParamsSet = false
        }
      })
      return areRequiredParamsSet
    },
    async onOffsetChange (offset) {
      this.pagination = this.getNewPagination(offset)
      await this.getData('onOffsetChange')
    },
    async onPageChange (newPage) {
      this.pagination = {
        currentPage: newPage,
        perPage: this.pagination.perPage
      }
      await this.getData('onPageChange')
    },
    onFilterPress (filter, type) {
      this.getCount = true
      this.clickFilter(filter, type)
    },
    clearFilter (type) {
      addToLog(`clear${type}Filter:searchKey: ` + this.searchKey, this.debug)
      if (this.searchKey === 'debug=true') this.debug = true
      else if (this.searchKey === 'debug=false') this.debug = false
      this.searchKey = ''
    },
    onDateTimeOrTimezoneChange (key, index, dateTimeOrTimezone, pageIndex) {
      this.setDateTime(dateTimeOrTimezone, pageIndex)
      this.checkParamValue(key, index, pageIndex)
    },
    onTimerChange (key, index, hourMinuteOrSecond, pageIndex) {
      this.setTimerValue(hourMinuteOrSecond, pageIndex)
      this.checkParamValue(key, index, pageIndex)
    },
    onTimePickerChange (key, index, timeOrTimezoneChange, pageIndex) {
      this.setTime(timeOrTimezoneChange, pageIndex)
      this.checkParamValue(key, index, pageIndex)
    },
    checkFieldControl (config, type) {
      return config.fieldControlSat
        ? config.fieldControlSat === type
        : config.fieldControl === type
    },
    getNestedValue (obj, path) {
      const keys = path.split('.')
      let value = obj
      for (const key of keys) {
        const valueExists = value && typeof value === 'object'
        if (valueExists && Array.isArray(value)) {
          value = value.find((item) => item.key === key || item.objectIdentifier === key)
        } else if (valueExists && key in value) {
          value = value[key]
        } else {
          return undefined
        }
      }
      return value
    },
    setNestedValue (obj, path, value) {
      const keys = path.split('.')
      let current = obj
      for (let i = 0; i < keys.length - 1; i++) {
        const segment = keys[i]
        if (Array.isArray(current[segment])) {
          const key = keys[i + 1]
          const config = current[segment].find(
            (config) => config.key === key || config.objectIdentifier === key
          )
          if (config) {
            current = config
            i++ // Skip the next segment since we've found the matching config
          } else {
            return obj // Path doesn't exist, return the original object
          }
        } else {
          current = current[segment]
        }
      }
      const lastSegment = keys[keys.length - 1]
      current[lastSegment] = value
      return obj
    },
    keyExists (configs, key) {
      return configs
        ? this.configType === 'contractDefWizard'
          ? this.valueExists(this.getNestedValue(configs, key))
          : false
        : false
    },
    accordianToggled (qnaIndex, fieldIndex, pageIndex) {
      this.QnA[pageIndex][fieldIndex] = this.QnA[pageIndex][fieldIndex].map(
        (bool, index) => {
          if (index === qnaIndex) bool = !bool
          else bool = false
          return bool
        }
      )
    },
    onCheckBoxChange (key, index, pageIndex) {
      const keyOrIndex = this.getKeyOrIndex(key, index)
      const uiConfig = this.getUiConfig(key || index, pageIndex)
      const value = uiConfig?.options.map((option) => {
        option.value = this.checkBox[pageIndex][index].includes(option.text)
        return option
      })
      this.setValue(keyOrIndex, value, pageIndex)
      this.checkParamValue(key, index, pageIndex)
    },
    checkContentWidth (configType, element, key, index) {
      if (document.getElementById(element)) {
        let text
        const inputElement = document.getElementById(element)
        const inputWidth = inputElement.offsetWidth * 0.6
        if (configType === 'contractsAction') {
          text = index ? this.action.parameters[index].value : this.action.parameters[key]
        } else if (configType === 'contractsParameter') {
          text = index ? this.parameter.parameters[index].value : this.parameter.parameters[key]
        }
        const canvas = document.createElement('canvas')
        const context = canvas.getContext('2d')
        const textWidth = context.measureText(text).width
        return textWidth >= inputWidth
      }
    },
    transformMetricListItem (s3Key) {
      const fn = 'transformMetricListItem'
      // sc: VE/ASSETS/Perth Metropolitan Area/Kensington/AU-6151-0001/2b8fe727-7587-41f6-ad8b-c9f4619aa37a/4022d8618ff0
      // s3Key: raw/metrics/VE/METRICS/ASSETS/Perth Metropolitan Area/Kensington/AU-6151-0001/2b8fe727-7587-41f6-ad8b-c9f4619aa37a/4022d8618ff0/14LD6UUVLA/DER_STATUS/2024/04/24/DER_STATUS_4022d8618ff0_14LD6UUVLA_20240424T204821Z.json
      try {
        const filename = s3Key.split('/').slice(-1)[0]
        const start = filename.split('_').slice(-1)[0].slice(0, 16)
        const metricId = filename.split('_').slice(-2)[0]
        const id = filename.split('_').slice(-3)[0]
        const metricType = filename.split(`_${id}`)[0]
        const sc = `${s3Key.split(id)[0]}${id}`.replace('raw/metrics', '').replace('/METRICS/', '')
        doLog(fn, { metricId, metricType, id, start, filename, sc }, this.debug)
        return { s3Key, metricId, metricType, id, start, filename, sc }
      } catch (e) {
        doLog(fn, { s3Key, error: e }, true)
      }
    },
    getTodayDate (format) {
      return moment().format(format || 'YYYY-MM-DD')
    },
    getDefaultFrom () {
      const fn = 'getDefaultFrom'
      const defaultFrom = longISOAddSeconds(this.getTodayDate(), -this.deltaDateInDays * 24 * 3600).slice(0, 10)
      doLog(fn, { defaultFrom }, this.debug)
      return defaultFrom
    },
    setDefaultFromDate () {
      this.fromDate = this.getDefaultFrom()
    },
    setDefaultToDate () {
      this.toDate = this.getTodayDate()
    },
    setDefaultCreatedFrom () {
      this.createdFrom = this.getDefaultFrom()
    },
    setDefaultCreatedTo () {
      this.createdTo = this.getTodayDate()
    }
  }
}

// VE Basic
/* global ve performance */
const del = ','
const newline = '\n'
const nl = (typeof module === 'undefined') ? '' : newline // add newline in node/lambda
const debugData = false

const addToLog = (logMsg, debug = debugData) => { // debug: 'true', 'false', 'booDebug', 'booDebugAws', etc
  // let tmpDebug = (typeof booDebug === 'undefined') ? true : booDebug // global booDebug is undefined in node/lambda
  // const shortDebug = (String(debug)).substr(0, 20)
  // tmpDebug = (typeof debug === 'undefined') ? tmpDebug : eval(shortDebug)
  if (debug) console.log(logMsg + nl)
  // todo: add code to 'log' to file?
}

const doLog = (...args) => {
  const dbg = args.length ? parseBoolean(args.pop()) : false
  if (!dbg) return
  try {
    console.log(args)
  } catch (e) {
    console.log(`failed to log ${args[0]}`)
  }
}

const hasProp = (thisObj, key) => {
  if (!thisObj) return false
  return Object.prototype.hasOwnProperty.call(thisObj, key)
}

const hasKeys = (thisObj) => {
  if (Array.isArray(thisObj) || typeof thisObj === 'string') return false
  try {
    return Object.keys(thisObj).length > 0
  } catch (e) {
    return false
  }
}

const hasId = (thisObj) => {
  return (hasProp(thisObj, 'meterId') || hasProp(thisObj, 'endPointId') || hasProp(thisObj, 'deviceId'))
}

const hasIds = (thisObj) => {
  return (hasProp(thisObj, 'meterIds') || hasProp(thisObj, 'endPointIds') || hasProp(thisObj, 'deviceIds'))
}

const getId = (thisObj) => {
  if (hasProp(thisObj, 'meterId')) return thisObj.meterId
  if (hasProp(thisObj, 'endPointId')) return thisObj.endPointId
  if (hasProp(thisObj, 'deviceId')) return thisObj.deviceId
  return null
}

const getIdName = (thisObj) => {
  if (hasProp(thisObj, 'meterId')) return 'meterId'
  if (hasProp(thisObj, 'meterIds')) return 'meterIds'
  if (hasProp(thisObj, 'endPointId')) return 'endPointId'
  if (hasProp(thisObj, 'endPointIds')) return 'endPointIds'
  if (hasProp(thisObj, 'deviceId')) return 'deviceId'
  if (hasProp(thisObj, 'deviceIds')) return 'deviceIds'
  return null
}

const getMeterId = (thisObj) => {
  if (hasProp(thisObj, 'meterId')) return thisObj.meterId
  return null
}

const getEndPointId = (thisObj) => {
  if (hasProp(thisObj, 'endPointId')) return thisObj.endPointId
  return null
}

const getDeviceId = (thisObj) => {
  if (hasProp(thisObj, 'deviceId')) return thisObj.deviceId
  return null
}

const getIds = (thisObj) => {
  if (hasProp(thisObj, 'meterIds')) return thisObj.meterIds
  if (hasProp(thisObj, 'endPointIds')) return thisObj.endPointIds
  if (hasProp(thisObj, 'deviceIds')) return thisObj.deviceIds
  return null
}

const getMeterIds = (thisObj) => {
  if (hasProp(thisObj, 'meterIds')) return thisObj.meterIds
  return null
}

const getEndPointIds = (thisObj) => {
  if (hasProp(thisObj, 'endPointIds')) return thisObj.endPointIds
  return null
}

const getDeviceIds = (thisObj) => {
  if (hasProp(thisObj, 'deviceIds')) return thisObj.deviceIds
  return null
}

const setCookie = (name, value, days) => {
  let expires = ''
  if (days) {
    const date = new Date()
    date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000))
    expires = '; expires=' + date.toUTCString()
  }
  document.cookie = name + '=' + (value || '') + expires + '; path=/'
  addToLog('veb:setCookie - name: ' + name + ', document.cookie: ' + document.cookie, debugData)
}

const getCookie = (name) => {
  const nameEQ = name + '='
  const ca = document.cookie.split(';')
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i]
    while (c.charAt(0) === ' ') c = c.substring(1, c.length)
    if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length)
  }
  return null
}

const eraseCookie = (name) => {
  document.cookie = name + '=; Max-Age=-99999999;'
}

const getVeEnvLocFromUrl = (vecfg, debug = false) => {
  // ac.veprod.village.energy, ac.vecddev.village.energy, ac.vecddevau1.village.energy
  const wlh = window.location.hostname
  if (!wlh.includes('.')) return null
  const hostPart2 = wlh.split('.')[1]
  addToLog('getVeEnvLocFromUrl:hostPart2: ' + hostPart2, true)
  const veEnvLocs = getVeEnvLocValues(vecfg, debug)
  const veEnvLocFromUrl = veEnvLocs.filter(el => el.envLocInUrl === hostPart2)[0].name
  addToLog('getVeEnvLocFromUrl:veEnvLocFromUrl: ' + veEnvLocFromUrl, true)
  return veEnvLocFromUrl
}

const setVeEnvLoc = (veEnvLoc, debug = debugData) => {
  addToLog('setVeEnvLoc:veEnvLoc: ' + veEnvLoc, debug)
  window.sessionStorage.setItem('veEnvLoc', veEnvLoc)
}

const getVeEnv = (debug = debugData) => {
  const veEnvLoc = getVeEnvLoc(debug)
  let veEnv = veEnvLoc.split('-')
  veEnv.splice(-2)
  veEnv = veEnv.join('-')
  addToLog('veb:getVeEnv:veEnv: ' + veEnv, debug)
  return veEnv
}

const getVeEnvLoc = (debug = debugData) => {
  const veEnvLoc = window.sessionStorage.getItem('veEnvLoc')
  addToLog('veb:getVeEnvLoc:veEnvLoc: ' + veEnvLoc, debug)
  return veEnvLoc
}

const getVeEnvShort = (debug) => { // todo: check if needed
  return getVeEnv(debug).replace(/-/g, '')
}

const getVeEnvLocShort = (debug) => { // todo: check if needed
  return getVeEnvLoc(debug).replace(/-/g, '')
}

const setUserProfile = (userProfile, dbg = false) => {
  const fn = 'setUserProfile'
  const userProfileText = JSON.stringify(userProfile)
  doLog(fn, { userProfile, userProfileText }, dbg)
  window.sessionStorage.setItem('userProfile', userProfileText)
}

const setAccessProfile = (accessProfile, dbg = false) => {
  const fn = 'setAccessProfile'
  const accessProfileText = JSON.stringify(accessProfile)
  doLog(fn, { accessProfile, accessProfileText }, dbg)
  window.sessionStorage.setItem('accessProfile', accessProfileText)
}

const getUserProfile = (dbg = false) => {
  const fn = 'getUserProfile'
  const userProfileText = window.sessionStorage.getItem('userProfile')
  const userProfile = JSON.parse(userProfileText)
  doLog(fn, { userProfileText, userProfile }, dbg)
  return userProfile
}

const setUserProfileSelectedVueView = (thisVueView, dbg = false) => {
  const fn = 'setUserProfileSelectedVueView'
  const userProfile = getUserProfile(dbg)
  userProfile.selectedVueView = thisVueView
  doLog(fn, { thisVueView, userProfile }, dbg)
  setUserProfile(userProfile, dbg)
}

const setUserProfileSelectedSecurityContext = (selectedSecurityContext, dbg = false) => {
  const fn = 'setUserProfileSelectedSecurityContext'
  const userProfile = getUserProfile(dbg)
  userProfile.selectedSecurityContext = selectedSecurityContext
  doLog(fn, { selectedSecurityContext, userProfile }, dbg)
  setUserProfile(userProfile, dbg)
}

const setUserProfileShowToastrNotifications = (showToastrNotifications, debug = false) => {
  const userProfile = getUserProfile(debug)
  userProfile.showToastrNotifications = showToastrNotifications
  setUserProfile(userProfile, debug)
}

const getAccessProfile = (dbg = false) => {
  const fn = 'getAccessProfile'
  const accessProfileText = window.sessionStorage.getItem('accessProfile')
  const accessProfile = JSON.parse(accessProfileText)
  doLog(fn, { accessProfileText, accessProfile }, dbg)
  return accessProfile
}

const toProperCase = (s) => {
  return s.toLowerCase().replace(/^(.)|\s(.)/g, function ($1) { return $1.toUpperCase() })
}

const toLowerCase = (s) => {
  return (typeof s === 'undefined') ? s : s.toLowerCase()
}

const toUpperCase = (s) => {
  return (typeof s === 'undefined') ? s : s.toUpperCase()
}

const toFirstUpperCase = (s) => {
  const first = s.substr(0, 1).toUpperCase()
  return first + s.substring(1)
}

const toFirstLowerCase = (s) => {
  const first = s.substr(0, 1).toLowerCase()
  return first + s.substring(1)
}

const getIndicesOf = (searchStr, str, caseSensitive = true) => {
  const searchStrLen = searchStr.length
  if (searchStrLen === 0) return []
  let startIndex = 0
  let index
  const indices = []
  if (!caseSensitive) {
    str = str.toLowerCase()
    searchStr = searchStr.toLowerCase()
  }
  while ((index = str.indexOf(searchStr, startIndex)) > -1) {
    indices.push(index)
    startIndex = index + searchStrLen
  }
  return indices
}

const shortISOToLongISO = (shortISO) => {
  // Assume short ISO does not support milliseconds
  // Short: 19780625T010203Z or 19780625T010203+10:00
  // Long: 1983-08-12T02:04:06Z or 1983-08-12T02:04:06-5:30
  const year = shortISO.substr(0, 4)
  const month = shortISO.substr(4, 2)
  const day = shortISO.substr(6, 2)
  if (shortISO.length === 8) {
    return year + '-' + month + '-' + day + 'T00:00:00Z'
  }
  const hours = shortISO.substr(9, 2)
  const minutes = shortISO.substr(11, 2)
  const seconds = shortISO.substr(13, 2)
  const offset = shortISO.substr(15, 6)
  return year + '-' + month + '-' + day + 'T' + hours + ':' + minutes + ':' + seconds + offset
}

const jsDateToLocalISO = (date, offset) => {
  const year = ('000' + date.getFullYear()).slice(-4)
  const month = ('0' + (date.getMonth() + 1)).slice(-2)
  const day = ('0' + date.getDate()).slice(-2)
  const hours = ('0' + date.getHours()).slice(-2)
  const minutes = ('0' + date.getMinutes()).slice(-2)
  const seconds = ('0' + date.getSeconds()).slice(-2)
  return year + '-' + month + '-' + day + 'T' + hours + ':' + minutes + ':' + seconds + offset
}

const decimalToTimezoneOffsetString = (input) => {
  const number = Math.abs(input)
  const min = ('0' + parseInt((number - parseInt(number, 10)) * 60, 10)).slice(-2)
  const hour = ('0' + parseInt(number, 10)).slice(-2)
  const sign = input >= 0 ? '+' : '-'
  return sign + hour + ':' + min
}

const utcISOToJSDate = (utcISO) => {
  const ms = parseInt(utcISO.substr(20, 3), 10) || 0
  const date = new Date(Date.UTC(
    parseInt(utcISO.substr(0, 4), 10),
    parseInt(utcISO.substr(5, 2), 10) - 1,
    parseInt(utcISO.substr(8, 2), 10),
    parseInt(utcISO.substr(11, 2), 10),
    parseInt(utcISO.substr(14, 2), 10),
    parseInt(utcISO.substr(17, 2), 10),
    ms
  ))
  return date
}

const utcISOToLocalISO = (utcISO, timezone) => {
  const date = utcISOToJSDate(utcISO)
  const locale = 'en-US'
  const utcDate = new Date(date.toLocaleString(locale, { timeZone: 'UTC' }))
  const localDate = new Date(date.toLocaleString(locale, { timeZone: timezone }))
  const offset = parseInt((localDate.getTime() - utcDate.getTime()) / 1000, 10) / 60 / 60
  const offsetString = decimalToTimezoneOffsetString(offset)
  return jsDateToLocalISO(localDate, offsetString)
}

const longISOToShortISO = (longISO) => {
  // Assume short ISO does not support milliseconds
  // Short: 19780625T010203Z or 19780625T010203+10:00
  // Long: 1983-08-12T02:04:06Z or 1983-08-12T02:04:06-5:30 or 2021-11-11T09:09:38.898003+00:00
  const year = longISO.substr(0, 4)
  const month = longISO.substr(5, 2)
  const day = longISO.substr(8, 2)
  if (longISO.length === 10) {
    return year + month + day + 'T000000Z'
  }
  const hours = longISO.substr(11, 2) || '00'
  const minutes = longISO.substr(14, 2) || '00'
  const seconds = longISO.substr(17, 2) || '00'
  let offset = longISO.substr(19, 6) || 'Z'
  // addToLog(`longISOToShortISO:year: ${year}, month: ${month}, day: ${day}, hours: ${hours}, minutes: ${minutes}, seconds: ${seconds}, offset: ${offset}`, true)
  if (longISO.includes('.')) {
    const afterDot = longISO.split('.')[1] // 898003+00:00
    if (afterDot.includes('+')) offset = '+' + afterDot.split('+')[1]
    if (afterDot.includes('-')) offset = '-' + afterDot.split('-')[1]
    if (afterDot.endsWith('Z')) offset = 'Z'
  }
  return year + month + day + 'T' + hours + minutes + seconds + offset
}

const shortISOUtcToLocal = (shortUTCISO, timezone) => {
  const longUTCISO = shortISOToLongISO(shortUTCISO)
  const localISO = utcISOToLocalISO(longUTCISO, timezone)
  return longISOToShortISO(localISO)
}

const formatDateWithTzOffsetForExcel = (value, tzOffset) => { // '2023-03-09T07:00:31.944235+00:00', '+08:00'
  if (value && value !== 'n/a') {
    if (!(value.endsWith('Z') || value.endsWith('+00:00'))) value = `${value.split('.')[0]}Z`
    const shortIsoUtc = longISOToShortISO(value)
    const shortIsoLocal = shortISOUtcToLocalByTzOffset(shortIsoUtc, tzOffset)
    const longIsoLocal = shortISOToLongISO(shortIsoLocal)
    const formattedDate = longIsoLocal.replace(/T/, ' ').slice(0, 19)
    // addToLog(`formatDateWithTzOffset:tzOffset: ${tzOffset}, value: ${value}, shortIsoUtc: ${shortIsoUtc}, shortIsoLocal: ${shortIsoLocal}, longIsoLocal: ${longIsoLocal}, formattedDate: ${formattedDate}`, true)
    return formattedDate
  } else {
    return 'n/a'
  }
}

const getTimezoneFromMicrogridId = (microgridId) => {
  // addToLog('getTimezoneFromMicrogridId - microgridId: ' + microgridId, booDebugData);
  const timezones = { IN: 'Asia/Kolkata', AU: 'Australia/Sydney', BM: 'Atlantic/Bermuda', KH: 'Asia/Phnom_Penh' }
  timezones['AU-2'] = 'Australia/Sydney'
  timezones['AU-7'] = 'Australia/Sydney'
  timezones['AU-4'] = 'Australia/Brisbane'
  timezones['AU-5'] = 'Australia/Adelaide'
  timezones['AU-6'] = 'Australia/Perth'
  timezones['AU-0'] = 'Australia/Darwin'
  let timezone = ''
  try { // get 'country' default timezone
    timezone = timezones[microgridId.substr(0, 2)]
  } catch (e) {
    // addToLog('getTimezoneFromMicrogridId - error: ' + e, booDebugData);
  }
  try { // get 'country + start of postcode' timezone
    const tz = timezones[microgridId.substr(0, 4)]
    timezone = (typeof tz === 'undefined') ? timezone : tz
  } catch (e) {
    // addToLog('getTimezoneFromMicrogridId - error: ' + e, true);
  }
  return timezone
}

const getTimezoneFromContext = (selectedContext) => { // todo: ensure selectedContext is provided
  // selectedContext = (typeof selectedContext === 'undefined') ? ve.ctx.selectedContext : selectedContext
  const microgridIdStartsWith = toUpperCase(selectedContext.substr(0, 2)) // todo: get microgridId from context if present
  return getTimezoneFromMicrogridId(microgridIdStartsWith)
}

const getTimezoneOffset = (shortIso, microgridId) => {
  const timezone = getTimezoneFromMicrogridId(microgridId)
  const offset = shortISOUtcToLocal(shortIso, timezone).substr(15)
  return offset
}

const getTimezoneOffsetInSeconds = (shortIso, microgridId) => {
  const offset = getTimezoneOffset(shortIso, microgridId) // +08:00, -04:00
  const [hour, minute] = offset.split(':').map(t => parseInt(t, 10))
  return 60 * ((hour * 60) + minute)
}

const getSecondsSinceMidnight = (d) => {
  const ssm = d.getUTCHours() * 3600 + d.getUTCMinutes() * 60 + d.getUTCSeconds()
  return ssm
}

const formatTimeStamp = (longIsoTimeStamp) => { // convert long ISO to short ISO format
  let ts = longIsoTimeStamp // ISO 8601 YYYY-MM-DDTHH:mm:ss.sssZ
  ts = ts.replace(/-/g, '')
  ts = ts.replace(/:/g, '')
  if (ts.length === 8) ts += 'T000000' // add time component if date only
  ts = ts.substr(0, 15) + 'Z'
  return ts
}

const getTzOffset = () => {
  return `${new Date()}`.split('GMT')[1].slice(0, 3) + ':' + `${new Date()}`.split('GMT')[1].slice(3, 5)
}

const getTimeStamp = () => { // get a new date in short ISO format (locale machine date time)
  // const date = new Date()
  // const longIsoTimeStamp = date.toISOString() // ISO 8601 YYYY-MM-DDTHH:mm:ss.sssZ
  // return `${longIsoTimeStamp.replace(/[-:]/g, '').slice(0, 15)}Z`
  return `${(new Date()).toISOString().replace(/[-:]/g, '').slice(0, 15)}Z`
}

const getIntervalTimeStamp = (interval = 5, future = false) => {
  const nowTS = getUnixTimeStamp()
  const prevTS = nowTS - (nowTS % interval)
  const intervalTS = (future) ? prevTS + interval : prevTS
  return unixTimestampToShortISO(intervalTS)
}

const getLocalStartOfDayTimeStamp = (tzOffset = getTzOffset(), dayOffset = 0) => {
  let localStart = getLocalIntervalTimeStamp(15, tzOffset, true) // '20220610T082715+08:00'
  if (dayOffset !== 0) {
    const day = localStart.slice(0, 8)
    const startDay = shortISOAddSeconds(day, dayOffset * 24 * 3600).slice(0, 8) // '20220609'
    localStart = startDay + 'T' + localStart.split('T')[1] // '20220609T082715+08:00'
  }
  const startArr = localStart.split('')
  startArr.splice(9, 6, '000000')
  const start = startArr.join('') // '20220609T000000+08:00'
  return start
}

const getLocalEndOfDayTimeStamp = (tzOffset = getTzOffset(), dayOffset = 0) => {
  const start = getLocalStartOfDayTimeStamp(tzOffset, dayOffset) // 20230130T235959+08:00
  return start.slice(0, 9) + '235959' + start.slice(15)
}

const getLocalStartOfDayLongISOTimeStamp = (tzOffset = getTzOffset(), dayOffset = 0) => {
  const start = getLocalStartOfDayTimeStamp(tzOffset, dayOffset)
  return shortISOToLongISO(start)
}

const getLocalEndOfDayLongISOTimeStamp = (tzOffset = getTzOffset(), dayOffset = 0) => {
  let start = getLocalStartOfDayTimeStamp(tzOffset, dayOffset) // 20230130T235959+08:00
  start = start.slice(0, 9) + '235959' + start.slice(15)
  return shortISOToLongISO(start)
}

const stringToTimezoneOffsetDecimal = (input) => { // '+08:00', 'Z'
  if (input === 'Z') return 0
  const sign = (input.slice(0, 1) === '+') ? 1 : -1
  const hour = parseInt(input.slice(1, 3), 10)
  const min = parseInt(input.slice(4, 6), 10) / 60
  return sign * (hour + min)
}

const getLocalIntervalTimeStamp = (interval = 5, tzOffset = '+00:00', future = false) => {
  const startUtc = getIntervalTimeStamp(interval, future) // now
  return shortISOUtcToLocalByTzOffset(startUtc, tzOffset)
}

const shortISOUtcToLocalByTzOffset = (startUtc, tzOffset = '+00:00') => {
  const tzOffsetInHours = stringToTimezoneOffsetDecimal(tzOffset) // 8
  const startLocal = shortISOAddSeconds(startUtc, tzOffsetInHours * 3600).replace(/Z/, tzOffset)
  return startLocal
}

const getMicrogridTimeStamp = (microgridId) => {
  const shortIso = getTimeStamp()
  const timezone = getTimezoneFromMicrogridId(microgridId)
  return shortISOUtcToLocal(shortIso, timezone)
}

const getMicrogridStartOfDayTimeStamp = (microgridId) => {
  const nowTS = getMicrogridTimeStamp(microgridId) // 20220115T200132+08:00
  const dt = nowTS.split('T')
  return dt[0] + 'T000000' + dt[1].slice(6)
}

const getUnixTimeStamp = (inMilliSeconds = false) => {
  return inMilliSeconds ? Date.now() : parseInt(Date.now() / 1000)
  // return shortISOToUnixTimestamp(getTimeStamp())
}

const getLongISOTimeStamp = (includeFraction) => { // get a new date in short ISO format (locale machine date time)
  let longIsoTimeStamp = new Date().toISOString() // ISO 8601 YYYY-MM-DDTHH:mm:ss.sssZ
  if (typeof includeFraction === 'undefined' || !includeFraction) longIsoTimeStamp = longIsoTimeStamp.substr(0, 19) + 'Z'
  return longIsoTimeStamp
}

const isShortISO = (isoDate) => { // 20230628T100911Z, 20230628T100911-03:00, 2023-06-28 10:09:11Z
  if (!isoDate) return false
  isoDate = `${isoDate}`.slice(0, 15) // 20230628T100911, 2023-06-28 10
  const hasT = isoDate.slice(8, 9) === 'T'
  const hasTwoHyphen = (`${isoDate}`.split('-').length === 3)
  return hasT && !hasTwoHyphen
}

const toLongISO = (shortIso) => {
  const longIso = isShortISO(shortIso) ? shortISOToLongISO(shortIso) : shortIso
  return longIso
}

const shortISOToDate = (shortIsoTimestamp) => {
  // ISO 8601 YYYY-MM-DDTHH:mm:ss.sssZ
  // short ISO 20181016T050430Z
  let ts = shortIsoTimestamp
  if (ts) {
    if (ts.length === 8) ts += 'T000000Z'
    if (ts.length === 9) ts += '000000Z'
    const offset = (ts.length > 15) ? ts.substr(15) : 'Z'
    const strYYYY = ts.substr(0, 4)
    const strMM = ts.substr(4, 2)
    const strDD = ts.substr(6, 2)
    const strHours = ts.substr(9, 2)
    const strMinutes = ts.substr(11, 2)
    const strSeconds = ts.substr(13, 2)
    const strDate = strYYYY + '-' + strMM + '-' + strDD + 'T' + strHours + ':' + strMinutes + ':' + strSeconds + offset // 'Z';
    return (new Date(strDate))
  }
}

const dateToShortISO = (dt) => {
  return longISOToShortISO(dt.toISOString())
}

const shortISOToUnixTimestamp = (shortIsoDate) => { // convert to Unix timestamp (in seconds)
  return Date.parse(shortISOToDate(shortIsoDate)) / 1000
}

const longISOToUnixTimestamp = (longIsoDate) => { // convert to Unix timestamp (in seconds)
  return shortISOToUnixTimestamp(longISOToShortISO(longIsoDate))
}

const unixTimestampToShortISO = (unixTimestamp, timezone) => { // unixTimestamp in seconds
  timezone = (typeof timezone === 'undefined') ? 'utc' : timezone
  const isoDate = new Date(unixTimestamp * 1000).toISOString()
  const longIsoLocalDate = utcISOToLocalISO(isoDate, timezone)
  let shortISOLocalDate = longISOToShortISO(longIsoLocalDate)
  shortISOLocalDate = shortISOLocalDate.replace(/\+00:00/, 'Z')
  return shortISOLocalDate
}

const unixTimestampToShortISOByTzOffset = (unixTimestamp, tzOffset = '+00:00') => { // unixTimestamp in seconds
  const pseudoUnixTimestamp = unixTimestamp + (3600 * stringToTimezoneOffsetDecimal(tzOffset))
  const isoDate = new Date(pseudoUnixTimestamp * 1000).toISOString()
  const longIsoLocalDate = utcISOToLocalISO(isoDate, 'UTC')
  let shortISOLocalDate = longISOToShortISO(longIsoLocalDate)
  shortISOLocalDate = shortISOLocalDate.replace(/\+00:00/, tzOffset)
  return shortISOLocalDate
}

/*
 * Adds time to a date. Modelled after MySQL DATE_ADD function.
 * Example: dateAdd(new Date(), 'minute', 30)  //returns 30 minutes from now.
 * https://stackoverflow.com/a/1214753/18511
 *
 * @param date  Date to start with
 * @param interval  One of: year, quarter, month, week, day, hour, minute, second
 * @param units  Number of units of the given interval to add.
 */
const dateAdd = (date, interval, units) => {
  if (units === 0) return date
  let ret = new Date(date) // don't change original date
  const checkRollover = function () { if (ret.getDate() !== date.getDate()) ret.setDate(0) }
  switch (interval.toLowerCase()) {
    case 'year' : ret.setFullYear(ret.getFullYear() + units); checkRollover(); break
    case 'quarter': ret.setMonth(ret.getMonth() + 3 * units); checkRollover(); break
    case 'month' : ret.setMonth(ret.getMonth() + units); checkRollover(); break
    case 'week' : ret.setDate(ret.getDate() + 7 * units); break
    case 'day' : ret.setDate(ret.getDate() + units); break
    case 'hour' : ret.setTime(ret.getTime() + units * 3600000); break
    case 'minute' : ret.setTime(ret.getTime() + units * 60000); break
    case 'second' : ret.setTime(ret.getTime() + units * 1000); break
    case 'millisecond' : ret.setTime(ret.getTime() + units); break
    default : ret = undefined; break
  }
  return ret
}

const dateAddShortISO = (dateShortIso, interval, units) => { // adds units intervals but returns shortISO in UTC
  dateShortIso += (dateShortIso.length === 8) ? 'T000000Z' : ''
  const dateStart = shortISOToDate(dateShortIso)
  const dateEnd = dateAdd(dateStart, interval, units)
  return longISOToShortISO(dateEnd.toISOString())
}

const dateAddShortISOLocal = (dateShortIso, interval, units, timezone) => {
  // adds units intervals but returns shortISO in same timezone as dateShortIso
  const dateStart = shortISOToDate(dateShortIso)
  const dateEnd = dateAdd(dateStart, interval, units)
  const dateEndLongISOLocal = utcISOToLocalISO(dateEnd.toISOString(), timezone)
  const dateShortISOLocal = longISOToShortISO(dateEndLongISOLocal)
  return dateShortISOLocal
}

const shortISOAddSeconds = (shortIso, addSeconds, timezone) => {
  shortIso = ensureShortISO(shortIso)
  timezone = (typeof timezone === 'undefined') ? 'UTC' : timezone
  const unixTS = shortISOToUnixTimestamp(shortIso) + addSeconds
  return unixTimestampToShortISO(unixTS, timezone)
}

const shortISOAddSecondsByTzOffset = (shortIso, addSeconds, tzOffset = '+00:00') => {
  const unixTS = shortISOToUnixTimestamp(shortIso) + addSeconds
  return unixTimestampToShortISOByTzOffset(unixTS, tzOffset)
}

const longISOAddSeconds = (longIso, addSeconds, timezone) => {
  const shortIso = longISOToShortISO(longIso)
  const newShortIso = shortISOAddSeconds(shortIso, addSeconds, timezone)
  const newLongIso = shortISOToLongISO(newShortIso)
  return newLongIso
}
const jsDateToLocalShortISO = (date, offset) => {
  const localShortIso = jsDateToLocalISO(date, offset)
  const localShortIsoWithoutOffset = localShortIso.substr(0, localShortIso.length - offset.length)
  return localShortIsoWithoutOffset.replace(/-/g, '').replace(/:/g, '') + offset
}

const shortISOStartOfNextMonth = (shortIso, debug) => {
  const hasTime = shortIso.length > 8
  const thisYear = parseInt(shortIso.substr(0, 4), 10)
  const thisMonth = parseInt(shortIso.substr(4, 2), 10)
  const incYear = (thisMonth < 12) ? 0 : 1
  const nextMonth = (thisMonth < 12) ? ('0' + (thisMonth + 1).toString()).slice(-2) : '01'
  let startOfNextMonth = (thisYear + incYear).toString() + nextMonth + '01'
  if (hasTime) startOfNextMonth += 'T000000Z'
  return startOfNextMonth
}

const shortISOEndOfMonth = (shortIso, debug) => {
  const hasTime = shortIso.length > 8
  const startOfNextMonth = shortISOStartOfNextMonth(shortIso, debug)
  const endOfMonth = shortISOAddSeconds(startOfNextMonth, -3600 * 24)
  return hasTime ? endOfMonth.substr(0, 8) + 'T235959Z' : endOfMonth.substr(0, 8)
}

const calcSecondsBetweenTwoTS = (from, to) => {
  from = ensureShortISO(from)
  to = ensureShortISO(to)
  return (shortISOToDate(to) - shortISOToDate(from)) / 1000
}

const ensureShortISO = (start) => {
  if (start.includes('-') || start.includes(' ') || start.slice(0, 18).includes(':')) return longISOToShortISO(start)
  return start
}

const ensureLongISO = (start) => { // 20230923T123445+08:00 => 2023-09-23T12:34:45+08:00
  if (!(start.slice(0, 10).includes('-') && start.slice(11, 16).includes(':'))) return shortISOToLongISO(start)
  return start
}

const calcReadsBetweenTwoTS = (from, to, interval) => { // number of 15-second periods between two timestamps
  interval = (typeof interval === 'undefined') ? 15 : interval
  const deltaInSeconds = calcSecondsBetweenTwoTS(from, to)
  const readsBetweenTwoTS = parseInt(deltaInSeconds / interval, 10)
  return readsBetweenTwoTS
}

const getDatePath = (date) => {
  if (date.includes('-')) date = longISOToShortISO(date)
  return [date.substr(0, 4), date.substr(4, 2), date.substr(6, 2)].join('/').replace(/\/00/g, '')
}

const getDateInFileName = (date) => {
  return getDatePath(date).replace(/\//g, '')
}

const getDateRange = (fromDate, toDate) => {
  const dateRange = []
  const from = fromDate.replace(/-/g, '')
  const to = toDate.replace(/-/g, '')
  if (to < from) return
  let thisTo = from
  do {
    dateRange.push(thisTo)
    thisTo = (shortISOAddSeconds(thisTo, 24 * 3600)).slice(0, 8)
  } while (thisTo <= to)
  return dateRange
}

const parseJwt = (token, debug = debugData) => {
  const base64Url = token.split('.')[1]
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/')
  const jsonPayload = decodeURIComponent(window.atob(base64).split('').map(c => {
    return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
  }).join(''))
  return JSON.parse(jsonPayload)
}

const decodeJwt = (token, debug = debugData) => {
  try {
    // return JSON.parse(atob(token.split('.')[1]))
    return parseJwt(token, debug)
  } catch (e) {
    return null
  }
}

const isTokenExpired = (token, debug = debugData) => {
  if (!token) return true
  const decodedToken = decodeJwt(token, debug)
  if (decodedToken && hasProp(decodedToken, 'exp')) {
    const exp = decodedToken.exp
    const nowTS = shortISOToUnixTimestamp(getTimeStamp())
    // isTokenExpired:exp: 1626230637490, nowTS: 1626239027
    const convertToSeconds = (unixTimestampToShortISO(exp).substr(0, 4) === getTimeStamp().substr(0, 4)) ? 1 : 1000
    addToLog('isTokenExpired:exp: ' + exp + ', nowTS: ' + nowTS + ', convertToSeconds: ' + convertToSeconds, debug)
    const delta = parseInt((exp / convertToSeconds) - nowTS, 10)
    addToLog('isTokenExpired: ' + ((exp / convertToSeconds) < nowTS) + ', delta: ' + delta, debug)
    return (exp / convertToSeconds) < nowTS
  }
}

const isAccessTokenExpired = (debug = debugData) => {
  const token = ve.amplify.getAmplifyAccessToken(debug) // todo: review if global ve should be used here
  return isTokenExpired(token, debug)
}

const initVeEnvLoc = (thisEnvLoc, debug = debugData) => { // set ve environment related vars
  if (thisEnvLoc) setVeEnvLoc(thisEnvLoc, debug)
  if (!thisEnvLoc) thisEnvLoc = getVeEnvLoc(debug)
  addToLog('veb:initVeEnvLoc:thisEnvLoc: ' + thisEnvLoc, debug)
  return true
}

const redirectToLogin = (debug = false) => {
  const currentUrl = window.location.href
  addToLog(`redirectToLogin:currentUrl: ${currentUrl}`, debug)
  // redirect to url without '#id_token=...' or '?code=
  const url = '' + window.location
  const splitUrl = url.split('.html')
  const newUrl = splitUrl[0] + '.html'
  addToLog(`redirectToLogin:newUrl: ${newUrl}`, debug)
  window.location = newUrl
}

const parseBoolean = (value) => {
  if (typeof value === 'boolean') return value
  if (typeof value === 'string') {
    value = value.toLowerCase().trim()
    if (value === '1') return true
    if (['true', 'on'].includes(value)) return true
    if (['false', 'off'].includes(value)) return false
  }
  if (typeof value === 'number') return (value === 1)
  return false
}

const isMustacheExpression = (paramValue) => { // mustache
  if (typeof paramValue !== 'string') return false
  return (paramValue.includes('{{') && paramValue.includes('}}'))
}

const isMathjsExpression = (paramValue) => { // mathjs.org
  if (typeof paramValue !== 'string') return false
  const expressionOperators = [' - ', ' + ', ' * ', ' / ', ' > ', ' >= ', ' < ', ' <= ', ' == ', ' or ', ' and ']
  for (const eo of expressionOperators) {
    if (paramValue.includes(eo)) return true
  }
  return false
}

const isLogicalExpression = (paramValue) => { // logical JS
  if (typeof paramValue !== 'string') return false
  const expressionOperators = [' || ', ' && ']
  for (const eo of expressionOperators) {
    if (paramValue.includes(eo)) return true
  }
  return false
}

const evaluateLogicalOr = (paramValue) => {
  const orOperator = ' || '
  const orValues = paramValue.split(orOperator)
  let returnValue = paramValue
  for (const orValue of orValues) {
    if (orValue.trim() !== '') {
      returnValue = orValue.trim() // first non-blank value
      break
    }
  }
  return returnValue
}

const evaluateLogicalAnd = (paramValue) => {
  const andOperator = ' && '
  const andValues = paramValue.split(andOperator)
  let returnValue = paramValue
  for (const andValue of andValues) {
    returnValue = andValue.trim() // last non-blank value
    if (andValue.trim() === '') break
  }
  return returnValue
}

const evaluateLogicalExpression = (paramValue) => { // e.g. 'min' || 'max' ... return 'min'
  const orOperator = paramValue.includes(' || ') ? ' || ' : null
  let andOperator = paramValue.includes(' && ') ? ' && ' : null
  if (!(orOperator || andOperator)) return paramValue
  let returnValue = paramValue
  if (orOperator) {
    returnValue = evaluateLogicalOr(paramValue)
    andOperator = returnValue.includes(' && ') ? ' && ' : null
  }
  if (andOperator) returnValue = evaluateLogicalAnd(returnValue)
  return returnValue
}

const evaluateMustache = (paramValue, mergedData) => { // faster than Mustache.render
  const dataKeys = Object.keys(mergedData)
  for (const key of dataKeys) {
    const mKey = `{{${key}}}`
    if (paramValue.includes(mKey)) {
      const re = new RegExp(mKey, 'g')
      paramValue = paramValue.replace(re, mergedData[key])
    }
  }
  return paramValue
}

const hasBlankLogicalOr = (paramValue) => {
  return paramValue.trim().startsWith('|| ')
}

const removeBlankLogicalOr = (paramValue) => { // work-around for mathjs error ' || (1.1111 >= 0.3333 ? 4.99 : 2.2222)'
  if (typeof paramValue !== 'string') return paramValue
  while (hasBlankLogicalOr(paramValue)) {
    paramValue = `${paramValue}`.trim().split('|| ').slice(1).join('|| ')
  }
  if (`${paramValue}`.trim() === '||') paramValue = ''
  return paramValue
}

const isApiExpression = (paramValue) => { // todo: handle Energy, Ledger api
  if (typeof paramValue !== 'string') return false
  const apiKeys = ['Dpe.', 'Energy.', 'Ledger.']
  for (const apiKey of apiKeys) {
    if (paramValue.startsWith(apiKey)) return true
  }
  return false
}

const getDynamicPriceJson = (dynamicPrice, durationInSeconds) => {
  // Dynamic price: {start: 20181016T050430Z, finish: 20181016T051415Z, price: [1.0, 1.1...], count: [10,5...]}
  const intervalInSeconds = 15
  durationInSeconds = Math.ceil(durationInSeconds / intervalInSeconds) * intervalInSeconds // round up to next 15s
  const count = durationInSeconds / intervalInSeconds
  let dtStart = new Date()
  let start = formatTimeStamp(dtStart.toISOString())
  // get seconds from midnight for the start of next interval
  const secondsSinceMidnight = getSecondsSinceMidnight(dtStart)
  const intStartOfNextInterval = secondsSinceMidnight + intervalInSeconds - (secondsSinceMidnight % intervalInSeconds)
  if (intStartOfNextInterval > secondsSinceMidnight) {
    start = formatTimeStamp(dtStart.toISOString())
    dtStart = dateAdd(dtStart.setUTCHours(0, 0, 0, 0), 'second', intStartOfNextInterval)
    start = formatTimeStamp(dtStart.toISOString())
  }
  const finish = shortISOAddSeconds(start, durationInSeconds)
  const strDPJson = { start, finish, price: [dynamicPrice], count: [count] }
  return strDPJson
}

const getSettingProfile = (settingName, settingValue, durationInSeconds) => {
  // { start: 20181016T050430Z, finish: 20181016T051415Z, <settingName>: [<settingValue>], count: [123]}
  const intervalInSeconds = 15
  durationInSeconds = Math.ceil(durationInSeconds / intervalInSeconds) * intervalInSeconds // round up to next 15s
  const count = durationInSeconds / intervalInSeconds
  let dtStart = new Date()
  let start = formatTimeStamp(dtStart.toISOString())
  // get seconds from midnight for the start of next interval
  const secondsSinceMidnight = getSecondsSinceMidnight(dtStart)
  const intStartOfNextInterval = secondsSinceMidnight + intervalInSeconds - (secondsSinceMidnight % intervalInSeconds)
  if (intStartOfNextInterval > secondsSinceMidnight) {
    start = formatTimeStamp(dtStart.toISOString())
    dtStart = dateAdd(dtStart.setUTCHours(0, 0, 0, 0), 'second', intStartOfNextInterval)
    start = formatTimeStamp(dtStart.toISOString())
  }
  const finish = shortISOAddSeconds(start, durationInSeconds)
  const settingProfile = { start, finish, count: [count] }
  settingProfile[settingName] = [settingValue]
  return settingProfile
}

const getDynamicPriceProfile = (dynamicPrice, durationInSeconds) => {
  return getSettingProfile('price', dynamicPrice, durationInSeconds)
}

const getCurtailmentProfile = (pSetPoint, durationInSeconds) => {
  return getSettingProfile('pSetPoint', pSetPoint, durationInSeconds)
}

const getPrice = (dtInterval, priceProfile) => {
  /*
  jsnDAP: {"start":"20190407T023035Z","finish":"20190408T023035Z","price":[4.8,4.7,4.6,4.5,4.3,4.2,4.1,3.9,3.8,3.7,3.6,3.5,3.4,3.3,3.3,3.2,3.2,3.2,3.2,3.2,3.2,3.2,3.2,3.2,3.2,3.3,3.3,3.3,3.3,3.3,3.3,3.3,3.3,3.2,3.2,3.2,3.2,3.2,3.2,3.1,3.1,3.1,3.1,3.1,3.1,3.1,3,3,3,3,3,2.9,2.9,2.9,2.8,2.8,2.7,2.6,2.6,2.5,2.4,2.3,2.3,2.2,2.1,2,2,1.9,1.9,1.8,1.8,1.8,1.8,1.8,1.8,1.8,1.9,1.9,1.9,2,2,2,2.1,2.1,2.1,2.2,2.2,2.2,2.3,2.3,2.3,2.4,2.4,2.5,2.5,2.6,2.7,2.8,2.9,3,3.1,3.3,3.4,3.6,3.7,3.9,4,4.2,4.3,4.5,4.6,4.7,4.8,4.8,4.9,4.9,4.9,4.8,4.8,4.7],"count":[5,5,5,5,5,5,5,5,5,5,5,5,5,5,5,5,5,5,5,5,5,5,5,5,5,5,5,5,5,5,5,5,5,5,5,5,5,5,5,5,5,5,5,5,5,5,5,5,5,5,5,5,5,5,5,5,5,5,5,5,5,5,5,5,5,5,5,5,5,5,5,5,5,5,5,5,5,5,5,5,5,5,5,5,5,5,5,5,5,5,5,5,5,5,5,5,5,5,5,5,5,5,5,5,5,5,5,5,5,5,5,5,5,5,5,5,5,5,5,5]}
  */
  if (typeof priceProfile === 'undefined') return null
  if (typeof priceProfile === 'string') priceProfile = JSON.parse(priceProfile)
  if (!priceProfile) return null
  // cater for missing start & finish in default day ahead price
  if (!hasProp(priceProfile, 'start') || !hasProp(priceProfile, 'finish')) {
    const dtNow = new Date()
    let strNow = dtNow.toISOString()
    strNow = strNow.replace(/-/g, '')
    // addToLog("strNow: " + strNow);
    if (!hasProp(priceProfile, 'start')) priceProfile.start = strNow.substr(0, 8) + 'T000000Z'
    if (!hasProp(priceProfile, 'finish')) priceProfile.finish = strNow.substr(0, 8) + 'T235959Z'
  }
  const intIntervalInSeconds = (typeof priceProfile.intervalInSeconds !== 'undefined') ? priceProfile.intervalInSeconds : 15 // use hardcoded if not present
  const dtStart = shortISOToDate(priceProfile.start)
  const dtFinish = shortISOToDate(priceProfile.finish)
  const intStartOffsetInSeconds = 10 // accept near future start timestamps
  if ((dtInterval + (intStartOffsetInSeconds * 1000)) < dtStart || dtInterval > dtFinish) return null // interval date is not within start & finish
  // addToLog('getPrice - dtInterval: ' + dtInterval + ', dtStart: ' + dtStart + ', dtFinish: ' + dtFinish);
  const secondsSinceStartOfProfile = (dtInterval - dtStart) / 1000
  // addToLog("secondsSinceStartOfProfile: " + secondsSinceStartOfProfile);
  const targetInterval = secondsSinceStartOfProfile / intIntervalInSeconds // priceProfile.intervalInSeconds;
  // addToLog("targetInterval: " + targetInterval);
  // Traverse price & count array to get the current price
  let intervalCount = 0
  for (const i in priceProfile.count) {
    const count = priceProfile.count[i]
    intervalCount += count
    if (targetInterval < intervalCount) {
      // addToLog('getPrice - intervalCount: ' + intervalCount + ', .start: ' + priceProfile.start + ', .finish: ' + priceProfile.finish + ', returning ' + priceProfile.price[i]);
      return priceProfile.price[i]
    }
  }
}

const getSettingFromProfile = (dtInterval, profile, settingName) => {
  // jsnDAP: {"start":"20190407T023035Z","finish":"20190408T023035Z","pSetPoint":[4.8,4.7],"count":[5,5]}
  if (typeof profile === 'undefined') return null
  if (typeof profile === 'string') profile = JSON.parse(profile)
  if (typeof dtInterval === 'string') dtInterval = shortISOToDate(dtInterval)
  if (!profile) return null
  // cater for missing start & finish in default day ahead price
  if (!hasProp(profile, 'start') || !hasProp(profile, 'finish')) {
    const dtNow = new Date()
    let strNow = dtNow.toISOString()
    strNow = strNow.replace(/-/g, '')
    // addToLog("strNow: " + strNow);
    if (!hasProp(profile, 'start')) profile.start = strNow.substr(0, 8) + 'T000000Z'
    if (!hasProp(profile, 'finish')) profile.finish = strNow.substr(0, 8) + 'T235959Z'
  }
  const intIntervalInSeconds = (typeof profile.intervalInSeconds !== 'undefined') ? profile.intervalInSeconds : 15 // use hardcoded if not present
  const dtStart = shortISOToDate(profile.start)
  const dtFinish = shortISOToDate(profile.finish)
  const intStartOffsetInSeconds = 10 // accept near future start timestamps
  if ((dtInterval + (intStartOffsetInSeconds * 1000)) < dtStart || dtInterval > dtFinish) return null // interval date is not within start & finish
  // addToLog('getPrice - dtInterval: ' + dtInterval + ', dtStart: ' + dtStart + ', dtFinish: ' + dtFinish);
  const secondsSinceStartOfProfile = (dtInterval - dtStart) / 1000
  // addToLog("secondsSinceStartOfProfile: " + secondsSinceStartOfProfile);
  const targetInterval = secondsSinceStartOfProfile / intIntervalInSeconds // priceProfile.intervalInSeconds;
  // addToLog("targetInterval: " + targetInterval);
  // Traverse settingName & count array to get the current settingValue
  let intervalCount = 0
  for (const i in profile.count) {
    const count = profile.count[i]
    intervalCount += count
    if (targetInterval < intervalCount) {
      // addToLog('getPrice - intervalCount: ' + intervalCount + ', .start: ' + priceProfile.start + ', .finish: ' + priceProfile.finish + ', returning ' + priceProfile.price[i]);
      return profile[settingName][i]
    }
  }
}

const getPSetPoint = (dtInterval, curtailmentProfile) => {
  // jsnDAP: { "start":"20190407T023035Z","finish":"20190408T023035Z", "pSetPoint":[4.8,4.7], "count":[5,5] }
  return getSettingFromProfile(dtInterval, curtailmentProfile, 'pSetPoint')
}

const getCurtailmentPercentage = (dtInterval, curtailmentProfile) => {
  // jsnDAP: { "start":"20190407T023035Z","finish":"20190408T023035Z", "pSetPoint":[4.8,4.7], "count":[5,5] }
  return getSettingFromProfile(dtInterval, curtailmentProfile, 'curtailmentPercentage')
}

const isNumeric = (n) => {
  return !isNaN(parseFloat(n)) && isFinite(n)
}

const isUndefined = (x) => {
  return (typeof x === 'undefined') || x === 'undefined'
}

const roundTo = (n, digits) => {
  if (!isNumeric(n)) return n
  n = parseFloat(n)
  let negative = false
  if (digits === undefined) {
    digits = 0
  }
  if (n < 0) {
    negative = true
    n = n * -1
  }
  const multiplicator = Math.pow(10, digits)
  n = parseFloat((n * multiplicator).toFixed(11))
  n = (Math.round(n) / multiplicator).toFixed(digits)
  if (negative) {
    n = (n * -1).toFixed(digits)
  }
  return parseFloat(n)
}

const roundToTwo = (num) => {
  return roundTo(num, 2)
}

const padTo = (n, digits) => {
  // pad number with 0's in 'missing' decimal places, e.g. n .. 0.43 with digits .. 5 should result in 0.43000
  // n is float or string and returns string
  n = '' + n
  const maxZeros = '0'.repeat(digits)
  if (n.includes('.')) {
    const nArray = n.split('.')
    return nArray[0] + '.' + (nArray[1] + maxZeros).substr(0, digits)
  } else {
    return n + '.' + maxZeros
  }
}

const padToTwo = (n) => {
  return padTo(n, 2)
}

const getRange = (intDataPoints, txt) => {
  txt = (typeof txt === 'undefined') ? 'e' : txt
  let range = (txt + ',').repeat(intDataPoints).split(',')
  range = range.map((e, i) => e + (1 + i))
  range.length-- // remove last item
  return range
}

const getRandomInt = (min, max) => {
  min = Math.ceil(min)
  max = Math.floor(max)
  return Math.floor(Math.random() * (max - min + 1)) + min
}

const getRandomNumber = (min, max) => {
  min = Math.ceil(min)
  max = Math.floor(max)
  return Math.floor(Math.random() * (max - min + 1)) + min
}

const sleep = async (ms) => {
  return new Promise(resolve => setTimeout(resolve, ms))
}

const calculatePower = (fltVoltage, fltCurrent) => { // calculate power in kW
  return roundTo(fltVoltage * fltCurrent / 1000, 8)
}

const convertEnergyToPower = (intEnergyInterval, fltEnergy, energyUnit, debug = false) => { // convert energy to power in kW
  energyUnit = (typeof energyUnit === 'undefined') ? 'kWh' : energyUnit
  const intConversionFactor = (energyUnit === 'Wh') ? 1000 : 1
  const fltPower = fltEnergy * (60 / intEnergyInterval) * 60 / intConversionFactor
  addToLog('convertEnergyToPower - fltEnergy: ' + fltEnergy + ', fltPower: ' + fltPower, debug)
  return fltPower
}

const convertPowerToEnergy = (intEnergyInterval, fltPower, energyUnit, debug = false) => {
  // assume power unit is kW - calculate energy as specified
  energyUnit = (typeof energyUnit === 'undefined') ? 'kWh' : energyUnit
  const intConversionFactor = (energyUnit === 'Wh') ? 1000 : 1
  const fltEnergy = fltPower / (60 / intEnergyInterval) / 60 * intConversionFactor
  addToLog('convertPowerToEnergy - fltPower: ' + fltPower + ', fltEnergy: ' + fltEnergy, debug)
  return fltEnergy
}

const getPowerInKiloWatt = (intEnergyInterval, fltEnergy, fltPower, debug = false) => {
  // convert power to kW
  addToLog('getPowerInKiloWatt:intEnergyInterval: ' + intEnergyInterval + ', fltEnergy: ' + fltEnergy + ', fltPower: ' + fltPower, debug)
  const convertedPower = convertEnergyToPower(intEnergyInterval, fltEnergy)
  const intConversionFactor = ((Math.abs(fltPower) / Math.abs(convertedPower)) > 10) ? 1000 : 1
  const power = fltPower / intConversionFactor
  addToLog('getPowerInKiloWatt:convertedPower: ' + convertedPower + ', power: ' + power, debug)
  return power
}

const copyJson = (jsonObject) => {
  return JSON.parse(JSON.stringify(jsonObject))
}

Math.radians = (degrees) => { // Converts from degrees to radians.
  return degrees * Math.PI / 180
}

Math.degrees = (radians) => { // Converts from radians to degrees.
  return radians * 180 / Math.PI
}

const calculateMidpoint = (from, to) => {
  const f = { lat: Math.radians(from.lat), lng: Math.radians(from.lng) }
  const t = { lat: Math.radians(to.lat), lng: Math.radians(to.lng) }
  const delta = Math.radians(to.lng - from.lng)
  const bX = Math.cos(t.lat) * Math.cos(delta)
  const bY = Math.cos(t.lat) * Math.sin(delta)
  const m = {}
  m.lat = Math.atan2(Math.sin(f.lat) + Math.sin(t.lat), Math.sqrt((Math.cos(f.lat) + bX) * (Math.cos(f.lat) + bX) + bY * bY))
  m.lng = f.lng + Math.atan2(bY, Math.cos(f.lat) + bX)
  m.lng = (m.lng + 3 * Math.PI) % (2 * Math.PI) - Math.PI // normalise to -180..+180°
  const middle = { lat: Math.degrees(m.lat), lng: Math.degrees(m.lng) }
  return middle
}

const calculateLatLng = (from, bearing, distance) => {
  // from: {lat: 123, lng: 123}, distance in meter
  const r = 6378137 // Radius of the Earth in meter
  const supportedBearings = { north: 0, northeast: 45, east: 90, southeast: 135, south: 180, southwest: 225, west: 270, northwest: 315 }
  const brng = (typeof bearing === 'string') ? supportedBearings[toLowerCase(bearing)] : bearing
  // convert to radians
  const f = { lat: Math.radians(from.lat), lng: Math.radians(from.lng) }
  const t = {}
  t.lat = Math.asin(Math.sin(f.lat) * Math.cos(distance / r) + Math.cos(f.lat) * Math.sin(distance / r) * Math.cos(brng))
  t.lng = f.lng + Math.atan2(Math.sin(brng) * Math.sin(distance / r) * Math.cos(f.lat), Math.cos(distance / r) - Math.sin(f.lat) * Math.sin(t.lat))
  // convert to degrees
  const to = { lat: Math.degrees(t.lat), lng: Math.degrees(t.lng) }
  return to
}

const calculateLatLngDistanceInMeter = (from, to) => {
  // from: {lat: 123, lng: 123}
  // to: {lat: 234, lng: 234}
  const r = 6378137 // Radius of the Earth
  const dLat = Math.radians(to.lat - from.lat)
  const dLng = Math.radians(to.lng - from.lng)
  const aaLat = Math.sin(dLat / 2) * Math.sin(dLat / 2)
  const abLat = Math.cos(Math.radians(from.lat)) * Math.cos(Math.radians(to.lat))
  const abLng = Math.sin(dLng / 2) * Math.sin(dLng / 2)
  const a = aaLat + abLat * abLng
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a))
  const d = roundToTwo(r * c) // meter
  return d
}

const getLatLngArray = (from, to, numberOfLatLng) => {
  // find centre
  // const brng = calculateBearing(from, to)
  // addToLog('getLatLngArray - brng: ' + brng, true);
  const distance = calculateLatLngDistanceInMeter(from, to)
  // addToLog('getLatLngArray - distance: ' + distance, true);
  // let centre = calculateLatLng(from, brng, distance / 2);
  const centre = calculateMidpoint(from, to)
  // addToLog('getLatLngArray - centre: ' + JSON.stringify(centre), true);
  // const i = parseInt(Math.sqrt(numberOfLatLng), 10)
  // const j = numberOfLatLng / i
  // addToLog('getLatLngArray - i: ' + i + ', j: ' + j, true);
  const result = []
  let b = 0 // start bearing
  const rStart = distance / numberOfLatLng / 5 // start radius
  let r = rStart
  let v = centre
  for (let i = 1; i <= numberOfLatLng; i++) {
    // addToLog('getLatLngArray - i: ' + i + ', b: ' + b + ', r: ' + r, true);
    v = calculateLatLng(v, b, r)
    result.push(v)
    b += 45
    r += rStart
  }
  return result
}

const getVeEnvLocValues = (vecfg, debug = debugData) => {
  // vecfg = {"envLocs":[{"name":"ve-cd-dev-au-1","awsAccountId":"123456789012"},...]}
  if (hasProp(vecfg, 'cfg')) vecfg = vecfg.cfg
  const veEnvLocs = hasProp(vecfg, 'envLocs') ? vecfg.envLocs : vecfg
  return veEnvLocs
}

const getVeEnvLocValue = (vecfg, veEnvLocKey, veEnvLocKeyValue, veEnvLocReturnKey, debug = debugData) => {
  // 'name', 've-cd-dev-au-1', 'awsAccountId'
  // 'index', 0, 'awsAccountId'
  // vecfg = {"envLocs":[{"name":"ve-cd-dev-au-1","awsAccountId":"123456789012"},...]}
  if (hasProp(vecfg, 'cfg')) vecfg = vecfg.cfg
  const veEnvLocs = hasProp(vecfg, 'envLocs') ? vecfg.envLocs : vecfg
  let veEnvLoc = []
  if (veEnvLocKey === 'index') {
    veEnvLoc = veEnvLocs[veEnvLocKeyValue]
  } else {
    veEnvLoc = veEnvLocs.filter(env => env[veEnvLocKey] === veEnvLocKeyValue)[0]
  }
  return hasProp(veEnvLoc, veEnvLocReturnKey) ? veEnvLoc[veEnvLocReturnKey] : null
}

const getVeEnvLocSatApiBaseURI = (vecfg, veEnvLocName, debug = debugData) => {
  return getVeEnvLocValue(vecfg, 'name', veEnvLocName, 'satApiBaseURI', debug)
}

const getVeEnvLocGqlUrl = (vecfg, veEnvLocName, debug) => {
  addToLog('getVeEnvLocGqlUrl:veEnvLocName: ' + veEnvLocName, true)
  return getVeEnvLocValue(vecfg, 'name', veEnvLocName, 'gqlUrl', debug)
}

const getVeEnvLocUserPoolId = (vecfg, veEnvLocName, debug = debugData) => {
  return getVeEnvLocValue(vecfg, 'name', veEnvLocName, 'userPoolId', debug)
}

const getVeEnvLocIdentityPoolId = (vecfg, veEnvLocName, debug = debugData) => {
  return getVeEnvLocValue(vecfg, 'name', veEnvLocName, 'identityPoolId', debug)
}

const getVeEnvLocCognitoAppClientId = (vecfg, veEnvLocName, debug = debugData) => {
  return getVeEnvLocValue(vecfg, 'name', veEnvLocName, 'cognitoAppClientId', debug)
}

const getVeEnvLocCognitoAppWebDomain = (vecfg, veEnvLocName, debug = debugData) => {
  return getVeEnvLocValue(vecfg, 'name', veEnvLocName, 'cognitoAppWebDomain', debug)
}

const getVeEnvLocCognitoAuthenticatedLogins = (vecfg, veEnvLocName, debug = debugData) => {
  return getVeEnvLocValue(vecfg, 'name', veEnvLocName, 'cognitoAuthenticatedLogins', debug)
}

const getVeEnvLocIotEndpoint = (vecfg, veEnvLocName, debug = debugData) => {
  return getVeEnvLocValue(vecfg, 'name', veEnvLocName, 'iotEndpoint', debug)
}

const getVeEnvLocAwsRegion = (vecfg, veEnvLocName, debug = debugData) => {
  return getVeEnvLocValue(vecfg, 'name', veEnvLocName, 'awsRegion', debug)
}

const getVeEnvLocDatalakeS3Bucket = (vecfg, veEnvLocName, debug = debugData) => {
  return getVeEnvLocValue(vecfg, 'name', veEnvLocName, 'datalakeS3Bucket', debug)
}

const getVeEnvLocDeploymentS3Bucket = (vecfg, veEnvLocName, debug = debugData) => {
  return getVeEnvLocValue(vecfg, 'name', veEnvLocName, 'deploymentS3Bucket', debug)
}

const getVeEnvLocKeyValues = (vecfg, veEnvLocReturnKey, debug = debugData) => {
  if (hasProp(vecfg, 'cfg')) vecfg = vecfg.cfg
  const veEnvLocs = hasProp(vecfg, 'envLocs') ? vecfg.envLocs : vecfg
  return veEnvLocs.map(envLoc => envLoc[veEnvLocReturnKey])
}

const getVeEnvLocNames = (vecfg, debug = debugData) => {
  return getVeEnvLocKeyValues(vecfg, 'name')
}

const getVeEnvLocDatalakeS3Buckets = (vecfg, debug = debugData) => {
  return getVeEnvLocKeyValues(vecfg, 'datalakeS3Bucket', debug)
}

const getVeEnvLocDeploymentS3Buckets = (vecfg, debug = debugData) => {
  return getVeEnvLocKeyValues(vecfg, 'deploymentS3Bucket', debug)
}

const getDatalakeBucketName = (vecfg, veEnvLoc, debug = debugData) => {
  if (!veEnvLoc) veEnvLoc = getVeEnvLoc(debug)
  return getVeEnvLocDatalakeS3Bucket(vecfg, veEnvLoc, debug)
}

const getCognitoRedirectUri = (debug = debugData) => {
  const wl = window.location
  let path = wl.pathname
  if (!path.startsWith('/index.html')) path = '/index.html'
  const uri = wl.protocol + '//' + wl.host + path
  return uri
}

const useGMC = (vecfg, debug = debugData) => {
  if (hasProp(vecfg, 'cfg')) vecfg = vecfg.cfg
  return vecfg.veGMCEnvLocs.includes(getVeEnvLoc(debug))
}

const getDataSource = (vecfg, debug = debugData) => {
  return useGMC(vecfg) ? 'gmc' : 'legacy'
}

const getEnergyKeyForAggregateBy = (aggregateBy, localDate, actualOrForecast = 'A', debug = false) => {
  let { owner, aggregateById } = aggregateBy
  // daily_transaction_aligned_<aggregateByIdInFilename>_date.parquet
  // sample
  // etl/energy/A/VE/TELEMETRY/Business/3DCSBN3T8U/daily_transaction_aligned_VE_TELEMETRY_Business_3DCSBN3T8U_20221028.parquet
  if (!aggregateById.startsWith(owner)) aggregateById = `${owner}/TELEMETRY/${aggregateById}` // todo: review
  aggregateById = `${aggregateById.replace('/ASSETS/', '/TELEMETRY/').replace('/CONTRACTS/', '/TELEMETRY/')}`
  const aggregateByIdFilename = aggregateById.replace(/\//g, '_')
  let filename
  // localDate: YYYYMMDD or YYYYMM
  const datePath = getDatePath(`${localDate}00`) // YYYY/MM/DD or YYYY/MM
  switch (actualOrForecast) {
    case 'A':
      filename = `${datePath.length === 7 ? 'monthly' : 'daily_transaction_aligned'}`
      filename += `_${aggregateByIdFilename}_${getDateInFileName(localDate)}.parquet`
      break
    case 'F': // not used atm
      filename = `daily_forecast_${aggregateByIdFilename}_${getDateInFileName(localDate)}.csv`
      break
    default:
      addToLog(`getEnergyKeyForAggregateBy:invalid value for actualOrForecast: ${actualOrForecast}`, true)
  }
  const key = `etl/energy/${actualOrForecast}/${aggregateById}/${datePath}/${filename}`
  addToLog(`getEnergyKeyForAggregateBy:key: ${key}`, debug)
  return key
}

const getEnergyKeyForEndPoint = (endPoint, localDate, actualOrForecast = 'A', debug = false) => {
  const { endPointId, securityContext, type } = endPoint
  // monthly_PM8002342958_202205.csv // NEM
  // daily_transaction_aligned_endPointId_date.parquet // VEDA, VECO // todo: implement
  // daily_forecast_PM8002342958_20220527.csv
  // sample monthly actual energy (for third party)
  // etl/energy/A/VE/TELEMETRY/Perth Metropolitan Area/Kensington/AU-6151-0001/090e0561-53aa-4019-b62d-beb4506a0f8d/
  // PMTest/2022/05/monthly_PMTest_202205.csv
  // monthly_mk116-02_202205.parquet
  let filename
  // localDate: YYYYMMDD or YYYYMM
  let datePath = getDatePath(`${localDate}00`) // YYYY/MM/DD or YYYY/MM
  switch (actualOrForecast) {
    case 'A':
      if (toLowerCase(type) === 'tp') { // nem12
        filename = `monthly_${endPointId}_${getDateInFileName(localDate).slice(0, 6)}.csv`
        datePath = datePath.slice(0, 7)
      } else {
        filename = `${datePath.length === 7 ? 'monthly' : 'daily_transaction_aligned'}`
        filename += `_${endPointId}_${getDateInFileName(localDate)}.parquet`
      }
      break
    case 'F':
      filename = `daily_forecast_${endPointId}_${getDateInFileName(localDate)}.csv`
      break
    default:
      addToLog(`getEnergyKeyForEndPoint:invalid value for actualOrForecast: ${actualOrForecast}`, true)
  }
  const key = `etl/energy/${actualOrForecast}/${securityContext.replace('/ASSETS/', '/TELEMETRY/')}/${datePath}/${filename}`
  addToLog('getEnergyKeyForEndPoint:key: ' + key, debug)
  return key
}

const getDailyEnergyKeyForEndPoint = (endPoint, localDate, actualOrForecast = 'A', debug = false) => {
  localDate = (localDate + '01').slice(0, 8) // ensure YYYYMMDD
  const key = getEnergyKeyForEndPoint(endPoint, localDate, actualOrForecast, debug)
  addToLog(`getDailyEnergyKeyForEndPoint:key: ${key}`, debug)
  return key
}

const getMonthlyEnergyKeyForEndPoint = (endPoint, localDate, actualOrForecast = 'A', debug = false) => {
  const key = getEnergyKeyForEndPoint(endPoint, localDate.slice(0, 6), actualOrForecast, debug)
  addToLog(`getMonthlyEnergyKeyForEndPoint:key: ${key}`, debug)
  return key
}

const convertJsonToCsv = async (jsonContent, includeHeader = true) => {
  let array = jsonContent // [{'a': 1, 'b': 2, 'c': 'xyz'}, ...]
  if (typeof array === 'string') {
    array = (array.startsWith('[')) ? array : '[' + array
    array = (array.endsWith(']')) ? array : array + ']'
    array = JSON.parse(array)
  }
  if (!Array.isArray(array)) array = [array]
  let csvContent = ''
  const keys = Object.keys(array[0])
  const header = keys.join(del)
  for (let i = 0; i < array.length; i++) {
    let line = ''
    for (const key of keys) {
      if (line !== '') line += ','
      line += array[i][key]
    }
    csvContent += line + newline
  }
  if (includeHeader) csvContent = `${header}${newline}${csvContent}`
  return csvContent
}

const getUniqueArray = (arrayWithDuplicates) => {
  if (!Array.isArray(arrayWithDuplicates)) return arrayWithDuplicates
  const uniqueArray = arrayWithDuplicates.reduce((previous, item) => {
    if (!previous.some(element => element === item)) {
      previous.push(item)
    }
    return previous
  }, [])
  return uniqueArray
}

const getUniqueListBy = (arr, key) => {
  return [...new Map(arr.map(item => [item[key], item])).values()]
}

const initPage = (debug = false) => {
  addToLog('initPage', debug)
  const wl = window.location
  const currentUrl = wl.href
  let newUrl = null
  addToLog('initPage: ' + getTimeStamp() + ', currentUrl: ' + currentUrl, debug)
  if (!currentUrl.includes('/index.html') && !currentUrl.includes('#')) {
    addToLog('initPage:appending /index.html', debug)
    newUrl = wl.protocol + '//' + wl.host + '/index.html'
    addToLog('initPage:newUrl: ' + newUrl, debug)
    window.location = newUrl
  } else if (!currentUrl.includes('.html') && !(currentUrl.includes('code='))) {
    newUrl = currentUrl.split('#').join('index.html#')
    addToLog('initPage:newUrl: ' + newUrl, debug)
    window.location = newUrl
  }
  addToLog('initPage:not changing currentUrl:newUrl: ' + newUrl, true)
}

const splitArray = (fullArray, splitCount) => {
  const clone = [...fullArray]
  const result = []
  if (splitCount < 1 || !Array.isArray(clone)) return clone
  while (clone.length) {
    const splitArray = clone.splice(0, splitCount)
    result.push(splitArray)
  }
  return result
}

const splitArrayAndSum = (fullArray, splitCount) => {
  const clone = [...fullArray]
  const result = []
  if (splitCount < 1 || !Array.isArray(clone)) return clone
  while (clone.length) {
    const splitSum = clone.splice(0, splitCount).reduce((s, e) => (e === null) ? s : s + e, null) // 0);
    result.push(splitSum)
  }
  return result
}

const expandArrayAndDivide = (fullArray, splitCount) => {
  const result = []
  for (const e of fullArray) {
    const v = isNumeric(e) ? e / splitCount : e
    const vs = new Array(splitCount).fill(v)
    result.push(...vs)
  }
  return result
}

const sumArrayElements = (a1, a2) => {
  return a1.map((v, i) => { return sumArray([v, a2[i]]) })
}

const multiplyArrayElements = (a, multiplier) => {
  return a.map(v => isNumeric(v) ? v * multiplier : v)
}

const sumArray = (a) => {
  let b = [...a]
  if (b.join('') === '') return null
  b = b.map(v => isNumeric(v) ? parseFloat(v) : null)
  return splitArrayAndSum(b, b.length)[0]
}

const getMaxLevelCount = (scs, sep, debug = false) => {
  const lvlCount = scs.map(sc => (sc.split(sep)).length)
  addToLog(`lvlCount: ${lvlCount}`, debug)
  return Math.max(...lvlCount)
}

const getValueCount = (sc, sep, debug = false) => {
  const valCount = sc.split(sep).length
  addToLog(`valCount: ${valCount}, sc: ${sc}`, debug)
  return valCount
}

const getLevelValues = (scs, lvlKey, lvlIndex, sep, debug = false) => {
  const scsMatch = scs.filter(sc => sc.startsWith(lvlKey) && getValueCount(sc, sep, debug) >= lvlIndex)
  addToLog(`scsMatch: ${scsMatch}`, debug)
  addToLog(`lvlKey: ${lvlKey}, lvlIndex: ${lvlIndex}`, debug)
  const lvlValues = getUniqueArray(scsMatch.map(sc => sc.split(sep).slice(lvlIndex - 1, lvlIndex).join(sep)))
  addToLog(`lvlValues: ${lvlValues}`, debug)
  return lvlValues
}

const buildLevel = (scs, sch, lvl, sep, debug = false) => {
  let tmpLvl = []
  const lvlIndex = parseInt(lvl.replace(/level/, ''), 10)
  const previousLvl = `level${lvlIndex - 1}`
  addToLog(`lvlIndex: ${lvlIndex}`, debug)
  switch (lvl) {
    case 'level1':
      return getUniqueArray(scs.map(sc => sc.split(sep)[0]))
    case 'level2':
      tmpLvl = []
      for (const key of sch[previousLvl]) {
        const tmpX = {}
        tmpX[key] = getLevelValues(scs, key, lvlIndex, sep, debug)
        tmpLvl.push(tmpX)
      }
      return tmpLvl
    default:
      tmpLvl = []
      for (const lvlObj of sch[previousLvl]) {
        // lvlObj: { VE: ['CONTRACTS', 'ASSETS'] }
        addToLog(`lvlObj: ${JSON.stringify(lvlObj)}`, debug)
        for (const key of Object.keys(lvlObj)) {
          const lvlKeys = lvlObj[key].map(v => `${key}${sep}${v}`)
          addToLog(`lvlKeys: ${lvlKeys}`, debug)
          for (const lvlKey of lvlKeys) {
            addToLog(`lvlKey: ${lvlKey}`, debug)
            const values = getLevelValues(scs, lvlKey, lvlIndex, sep, debug)
            const tmpX = {}
            tmpX[lvlKey] = values
            if (values.length > 0) tmpLvl.push(tmpX)
          }
        }
      }
      return tmpLvl
  }
}

const buildHierarchy = (scs, sch = {}, debug = false) => {
  const sep = '/'
  const lvlCount = getMaxLevelCount(scs, sep, debug)
  addToLog(`lvlCount: ${lvlCount}`, debug)
  for (let i = 1; i <= lvlCount; i++) {
    const lvl = `level${i}`
    addToLog(`i: ${i}, lvl: ${lvl}`, debug)
    sch[lvl] = buildLevel(scs, sch, lvl, sep, debug)
  }
  return sch
}

const scrollToCenter = async (rowId, rowInfo, containerId) => {
  if (rowInfo) {
    await sleep(100)
    const element = document.getElementById(rowId)
    const containerChild = document.getElementById(containerId)
    const container = containerChild.parentElement
    if (container && element) scrollToElem(container, element)
  }
}

const scrollToElem = (container, elm) => {
  const pos = getRelativePos(elm, container)
  scrollTo(container, pos.top, 1)
}

const getRelativePos = (elm, container) => {
  const pPos = container.getBoundingClientRect()
  const cPos = elm.getBoundingClientRect()
  const pos = {}
  pos.top = cPos.top - pPos.top + container.parentElement.scrollTop
  pos.right = cPos.right - pPos.right
  pos.bottom = cPos.bottom - pPos.bottom
  pos.left = cPos.left - pPos.left
  return pos
}

const scrollTo = (element, to, duration, onDone) => {
  const start = element.scrollTop
  const change = to - start
  const startTime = performance.now()
  let now
  let elapsed
  let t
  const animateScroll = () => {
    now = performance.now()
    elapsed = (now - startTime) / 1000
    t = (elapsed / duration)
    element.scrollTop = start + change * easeInOutQuad(t)
    if (t < 1) { window.requestAnimationFrame(animateScroll) } else { onDone && onDone() }
  }
  animateScroll()
}

const easeInOutQuad = (t) => { return t < 0.5 ? 2 * t * t : -1 + (4 - 2 * t) * t }

const stickyHeaderFix = async () => {
  await sleep(100)
  const tableColumns = document.getElementsByTagName('th')
  const columns = [...tableColumns]
  columns.forEach(el => {
    if (el.classList.contains('position-relative')) {
      el.classList.remove('position-relative')
      el.classList.add('position-sticky')
    } else el.classList.add('position-sticky')
  })
}

const calculatePageOffset = (newPagination, searched) => {
  let offset = 0
  if (searched) {
    offset = 0
    newPagination.currentPage = 1
  } else {
    offset = newPagination.currentPage === 1
      ? 0
      : (newPagination.currentPage - 1) * newPagination.perPage
  }
  return { offset, newPagination }
}

const validateJWT = (token, currentContext) => {
  const currentDate = new Date()
  const currentSeconds = Math.round(currentDate.getTime() / 1000)
  if ((token.authorisedSecurityContext === currentContext) && (token.exp >= currentSeconds)) {
    return true
  }
  return false
}

const debounce = (func, timeout = 3000) => {
  let timer
  return function () {
    const context = this
    const args = arguments
    clearTimeout(timer)
    timer = setTimeout(() => func.apply(context, args), timeout)
  }
}

const jsonSize = (json) => { // kB
  const size = new TextEncoder().encode(JSON.stringify(json)).length
  return roundToTwo(size / 1024)
}

const isEspressifMacAddress = (macAddress, debug = false) => {
  if (typeof macAddress !== 'string') return false
  const espressif = ':10521c:18fe34:240ac4:2462ab:246f28:24b2de:2c3ae8:2cf432:30aea4:3c71bf:4022d8:40f520:483fda:4c11ae:500291:545aa6:5ccf7f:600194:68c63a:70039f:7c9ebd:7cdfa1:807d3a:840d8e:84cca8:84f3eb:8caab5:9097d5:98f4ab:a020a6:a47b9d:a4cf12:ac67b2:acd074:b4e62d:b8f009:bcddc2:c44f33:c82b96:cc50e3:d8a01d:d8bfc0:d8f15b:dc4f22:e09806:ecfabc:f008d1:f4cfa2:fcf5c4:'
  const macPart = `:${macAddress.slice(0, 6)}:`
  const isEspressif = espressif.includes(macPart) && macAddress.length === 12
  addToLog(`isEspressifMacAddress:macPart: ${macPart}:isEspressif: ${isEspressif}`, debug)
  return isEspressif
}

const isValidUuidv4 = (id) => { // Regular expression to check if string is a valid UUID
  const regexExp = /^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/gi
  return regexExp.test(id)
}

const calcStartTimeLabels = (fromDate, toDate, intDataPoints, timeOnly = false) => {
  const addOneDayInSeconds = (fromDate === toDate || fromDate.length === 8) ? 24 * 3600 : 0 // 86400
  const deltaInSeconds = calcSecondsBetweenTwoTS(fromDate, toDate) + addOneDayInSeconds // '20220601', '20220602')
  let tzOffset = fromDate.slice(15)
  if (['', 'Z'].includes(tzOffset)) tzOffset = '+00:00'
  const intervalInSeconds = deltaInSeconds / intDataPoints
  const start = []
  for (const i in getRange(intDataPoints)) {
    const dt = shortISOAddSecondsByTzOffset(fromDate, intervalInSeconds * i, tzOffset).split('T')
    let t = `${dt[1].slice(0, 2)}:${dt[1].slice(2, 4)}`
    if (deltaInSeconds <= 7200) t = `${t}:${dt[1].slice(4, 6)}`
    const d = timeOnly ? '' : `${dt[0]} `
    start.push(`${d}${t}`)
  }
  return start
}

const getArrayOfNull = (intDataPoints) => { // create array of null with specified size
  return new Array(intDataPoints).fill(null)
}

const sortArrayElementsByKeyValue = (arr, key, order = 'asc', dbg = false) => {
  if (!Array.isArray(arr)) return arr
  return [...arr].sort(compareValues(key, order, dbg))
}

const sortEndPointsByConnection = (endPoints, order = 'asc') => {
  if (!Array.isArray(endPoints)) return endPoints
  return [...endPoints].sort(compareEndPointConnection(order))
}

const compareEndPointConnection = (order = 'asc') => {
  return function innerSort (a, b) {
    const varA = `z_${a?.connection?.serialNo}_${a?.connection?.ipAddr}_${a?.connection?.tcpPort}_${a?.connection?.unitId}_${a?.connection?.phaseId}`
    const varB = `z_${b?.connection?.serialNo}_${b?.connection?.ipAddr}_${b?.connection?.tcpPort}_${b?.connection?.unitId}_${b?.connection?.phaseId}`
    const comparison = (varA > varB) ? 1 : (varA < varB) ? -1 : 0
    return (order === 'desc') ? (comparison * -1) : comparison
  }
}

const sortObjectWithStringKeys = (obj) => {
  return Object.fromEntries(Object.entries(obj).sort())
}

const compareValues = (key, order = 'asc', dbg = false) => {
  return function innerSort (a, b) {
    // property doesn't exist on either object
    if (!hasProp(a, key) || !hasProp(b, key)) return 0
    const varA = (typeof a[key] === 'string') ? a[key].toUpperCase() : a[key]
    const varB = (typeof b[key] === 'string') ? b[key].toUpperCase() : b[key]
    const comparison = (varA > varB) ? 1 : (varA < varB) ? -1 : 0
    return (order === 'desc') ? (comparison * -1) : comparison
  }
}

export {
  addToLog,
  calcReadsBetweenTwoTS,
  calcSecondsBetweenTwoTS,
  calculatePower,
  convertEnergyToPower,
  convertPowerToEnergy,
  copyJson,
  dateAdd,
  dateAddShortISO,
  dateAddShortISOLocal,
  dateToShortISO,
  decimalToTimezoneOffsetString,
  decodeJwt,
  doLog,
  getSecondsSinceMidnight,
  ensureShortISO,
  ensureLongISO,
  eraseCookie,
  evaluateLogicalAnd,
  evaluateLogicalExpression,
  evaluateLogicalOr,
  formatTimeStamp,
  getCognitoRedirectUri,
  getDynamicPriceJson,
  getDynamicPriceProfile,
  getCurtailmentProfile,
  getCookie,
  getDataSource,
  getDatePath,
  getDateInFileName,
  getDateRange,
  getIntervalTimeStamp,
  getLocalStartOfDayTimeStamp,
  getLocalEndOfDayTimeStamp,
  getLocalStartOfDayLongISOTimeStamp,
  getLocalEndOfDayLongISOTimeStamp,
  getLocalIntervalTimeStamp,
  shortISOUtcToLocalByTzOffset,
  getLongISOTimeStamp,
  getMicrogridTimeStamp,
  getMicrogridStartOfDayTimeStamp,
  getUnixTimeStamp,
  getPSetPoint,
  getCurtailmentPercentage,
  getPrice,
  getTzOffset,
  getTimeStamp,
  getTimezoneFromContext,
  getTimezoneFromMicrogridId,
  getTimezoneOffset,
  getTimezoneOffsetInSeconds,
  getUniqueArray,
  getUniqueListBy,
  getVeEnv,
  getVeEnvShort,
  getVeEnvLoc,
  getVeEnvLocShort,
  setUserProfile,
  setAccessProfile,
  getUserProfile,
  setUserProfileSelectedVueView,
  setUserProfileSelectedSecurityContext,
  setUserProfileShowToastrNotifications,
  getAccessProfile,
  getDatalakeBucketName,
  getVeEnvLocCognitoAppClientId,
  getVeEnvLocCognitoAppWebDomain,
  getVeEnvLocCognitoAuthenticatedLogins,
  getVeEnvLocDatalakeS3Bucket,
  getVeEnvLocDatalakeS3Buckets,
  getVeEnvLocDeploymentS3Bucket,
  getVeEnvLocDeploymentS3Buckets,
  getVeEnvLocGqlUrl,
  getVeEnvLocIdentityPoolId,
  getVeEnvLocIotEndpoint,
  getVeEnvLocNames,
  getVeEnvLocAwsRegion,
  getVeEnvLocSatApiBaseURI,
  getVeEnvLocUserPoolId,
  getVeEnvLocValues,
  getVeEnvLocValue,
  hasProp,
  hasKeys,
  hasId,
  hasIds,
  getId,
  getIdName,
  getMeterId,
  getEndPointId,
  getDeviceId,
  getIds,
  getMeterIds,
  getEndPointIds,
  getDeviceIds,
  getLatLngArray,
  getPowerInKiloWatt,
  // initVeEnv,
  initVeEnvLoc,
  isAccessTokenExpired,
  isApiExpression,
  isLogicalExpression,
  isMathjsExpression,
  isMustacheExpression,
  isNumeric,
  isUndefined,
  isTokenExpired,
  isShortISO,
  jsDateToLocalISO,
  jsDateToLocalShortISO,
  longISOAddSeconds,
  longISOToShortISO,
  longISOToUnixTimestamp,
  padTo,
  padToTwo,
  getRange,
  getRandomInt,
  getRandomNumber,
  parseBoolean,
  parseJwt,
  redirectToLogin,
  evaluateMustache,
  removeBlankLogicalOr,
  roundTo,
  roundToTwo,
  setCookie,
  getVeEnvLocFromUrl,
  setVeEnvLoc,
  shortISOAddSeconds,
  shortISOAddSecondsByTzOffset,
  shortISOToDate,
  shortISOToLongISO,
  shortISOToUnixTimestamp,
  shortISOUtcToLocal,
  formatDateWithTzOffsetForExcel,
  shortISOStartOfNextMonth,
  shortISOEndOfMonth,
  sleep,
  sortArrayElementsByKeyValue,
  sortEndPointsByConnection,
  sortObjectWithStringKeys,
  toFirstUpperCase,
  toFirstLowerCase,
  getIndicesOf,
  toLongISO,
  toLowerCase,
  toProperCase,
  toUpperCase,
  unixTimestampToShortISO,
  unixTimestampToShortISOByTzOffset,
  useGMC,
  utcISOToJSDate,
  utcISOToLocalISO,
  getEnergyKeyForAggregateBy,
  getEnergyKeyForEndPoint,
  getDailyEnergyKeyForEndPoint,
  getMonthlyEnergyKeyForEndPoint,
  convertJsonToCsv,
  initPage,
  splitArray,
  splitArrayAndSum,
  expandArrayAndDivide,
  sumArrayElements,
  multiplyArrayElements,
  sumArray,
  getMaxLevelCount,
  getValueCount,
  getLevelValues,
  buildLevel,
  buildHierarchy,
  scrollToCenter,
  stickyHeaderFix,
  calculatePageOffset,
  validateJWT,
  debounce,
  jsonSize,
  isEspressifMacAddress,
  isValidUuidv4,
  calcStartTimeLabels,
  getArrayOfNull
}
